import Analyst from '@cfra-nextgen-frontend/shared/src/assets/icons/analyst.svg';
import BiggestConcern from '@cfra-nextgen-frontend/shared/src/assets/icons/bc.svg';
import BespokeEdge from '@cfra-nextgen-frontend/shared/src/assets/icons/chart-with-human.svg';
import Industry from '@cfra-nextgen-frontend/shared/src/assets/icons/chart.svg';
import CompanyResearch from '@cfra-nextgen-frontend/shared/src/assets/icons/company.svg';
import BespokeServices from '@cfra-nextgen-frontend/shared/src/assets/icons/tree.svg';
import BespokeAdmin from '@cfra-nextgen-frontend/shared/src/assets/icons/user.svg';
import AccountingLensIcon from '@cfra-nextgen-frontend/shared/src/assets/images/AccountingLensIcon.svg';
import BespokeIcon from '@cfra-nextgen-frontend/shared/src/assets/images/BespokeIcon.svg';
import HomeIcon from '@cfra-nextgen-frontend/shared/src/assets/images/HomeIcon.svg';
import LegalEdgeIcon from '@cfra-nextgen-frontend/shared/src/assets/images/LegalEdgeIcon.svg';
import LibraryIcon from '@cfra-nextgen-frontend/shared/src/assets/images/LibraryIcon.svg';
import ScoresIcon from '@cfra-nextgen-frontend/shared/src/assets/images/ScoresIcon.svg';
import WatchlistIcon from '@cfra-nextgen-frontend/shared/src/assets/images/WatchlistIcon.png';
import { TopNavItem } from '@cfra-nextgen-frontend/shared/src/components/TopNavigation/SharedTopNavigation';
import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { LookupItems, ResearchTypeId, ScoresId, RegionId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';
import { UserEntitlement } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';

const getAllUserEntitlements = (userEntitlements?: UserEntitlementsResponseData) =>
    userEntitlements?.packages
        .filter((pckg) => pckg.entitlements !== undefined)
        .flatMap((pckg) => pckg.entitlements, Infinity) || [];

const checkUserEntitlements = (researchIDSet: Set<string>, userEntitlements?: UserEntitlementsResponseData) => {
    const userEntitlementsList: (UserEntitlement | undefined)[] = getAllUserEntitlements(userEntitlements);

    return !(
        userEntitlementsList.length > 0 &&
        userEntitlementsList.some(
            (userEntitlement) =>
                userEntitlement?.item_lid === LookupItems.ResearchTypeId &&
                researchIDSet.has(userEntitlement?.value.toString() || ''),
        )
    );
};

const checkBiggestConcernsEntitlement = (userEntitlements?: UserEntitlementsResponseData) => {
    const userEntitlementsList: (UserEntitlement | undefined)[] = getAllUserEntitlements(userEntitlements);

    let hasCompanyReportEntitlement = false;
    let hasRegionEntitlement = false;

    const regionIDSet = new Set([
        RegionId.FRSAmericas.toString(),
        RegionId.FRSAsia.toString(),
        RegionId.FRSEurope.toString(),
    ]);

    for (const userEntitlement of userEntitlementsList) {
        if (
            userEntitlement?.item_lid === LookupItems.ResearchTypeId &&
            userEntitlement.value.toString() === ResearchTypeId.FrsCompanyReport.toString()
        ) {
            hasCompanyReportEntitlement = true;
        }

        if (userEntitlement?.item_lid === LookupItems.RegionLid && regionIDSet.has(userEntitlement.value.toString())) {
            hasRegionEntitlement = true;
        }

        // Break early if both entitlements are found
        if (hasCompanyReportEntitlement && hasRegionEntitlement) {
            break;
        }
    }

    return !(hasCompanyReportEntitlement && hasRegionEntitlement);
};


export const topNavItems: Record<string, TopNavItem> = {
    Home: {
        name: 'Home',
        href: '/',
        type: 'link',
        icon: HomeIcon,
        length: 45,
    },
    AccountingLens: {
        name: PageNames.AccountingLens,
        href: PageNamesToRoutePaths[PageNames.AccountingLens],
        type: 'tab',
        icon: AccountingLensIcon,
        shouldHideItem: (userEntitlements?: UserEntitlementsResponseData) => {
            return (
                checkBiggestConcernsEntitlement(userEntitlements) &&
                checkUserEntitlements(
                    new Set([
                        ResearchTypeId.FrsCompanyReport.toString(),
                        ResearchTypeId.FrsNotebooks.toString(),
                        ResearchTypeId.FrsEarningsAlert.toString(),
                    ]),
                    userEntitlements,
                ) &&
                checkUserEntitlements(
                    new Set([
                        ResearchTypeId.FrsIRAPS.toString(),
                        ResearchTypeId.FrsIndustryReport.toString(),
                        ResearchTypeId.FrsEducationalReport.toString(),
                    ]),
                    userEntitlements,
                )
            );
        },
        popup: {
            title: 'Accounting Lens',
            description:
                'Uncover hidden discrepancies between reported and true financial health. Our proprietary forensic accounting research identifies companies with red flags due to questionable financial reporting, weak operational metrics, or governance issues, advising investors to exercise extreme caution.',
            links_title: 'Links',
            links: [
                {
                    icon: BiggestConcern,
                    name: PageNames.AccountingLensRiskMonitor,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensRiskMonitor],
                    shouldHideLink: (userEntitlements?: UserEntitlementsResponseData) => {
                        return checkBiggestConcernsEntitlement(userEntitlements);
                    },
                },
                {
                    icon: CompanyResearch,
                    name: PageNames.AccountingLensCompanyResearch,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensCompanyResearch],
                    shouldHideLink: (userEntitlements?: UserEntitlementsResponseData) => {
                        return checkUserEntitlements(
                            new Set([
                                ResearchTypeId.FrsCompanyReport.toString(),
                                ResearchTypeId.FrsNotebooks.toString(),
                                ResearchTypeId.FrsEarningsAlert.toString(),
                            ]),
                            userEntitlements,
                        );
                    },
                },
                {
                    icon: Industry,
                    name: PageNames.AccountingLensIndustryResearch,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensIndustryResearch],
                    shouldHideLink: (userEntitlements?: UserEntitlementsResponseData) => {
                        return checkUserEntitlements(
                            new Set([
                                ResearchTypeId.FrsIRAPS.toString(),
                                ResearchTypeId.FrsIndustryReport.toString(),
                                ResearchTypeId.FrsEducationalReport.toString(),
                            ]),
                            userEntitlements,
                        );
                    },
                },
                {
                    icon: Analyst,
                    name: 'Analyst Directory',
                    href: PageNamesToRoutePaths[PageNames.AccountingLensAnalystDirectory],
                },
            ],
        },
        length: 127,
    },
    ResearchHub: {
        name: PageNames.ResearchHub,
        href: PageNamesToRoutePaths[PageNames.ResearchHub],
        type: 'link',
        icon: LibraryIcon,
        length: 105,
        shouldHideItem: (userEntitlements?: UserEntitlementsResponseData) => {
            return checkUserEntitlements(
                new Set([
                    ResearchTypeId.LegalReport.toString(),
                    ResearchTypeId.LegalAlert.toString(),
                    ResearchTypeId.LegalEducational.toString(),
                    ResearchTypeId.PolicyNewsletterToWealth.toString(),
                    ResearchTypeId.FrsCompanyReport.toString(),
                    ResearchTypeId.FrsNotebooks.toString(),
                    ResearchTypeId.FrsEarningsAlert.toString(),
                    ResearchTypeId.FrsIndustryReport.toString(),
                    ResearchTypeId.FrsEducationalReport.toString(),
                    ResearchTypeId.FrsIRAPS.toString(),
                    ResearchTypeId.FrsBanksOrFIReport.toString(),
                    ResearchTypeId.FrsDailySnapshot.toString(),
                    ResearchTypeId.LegalPipeline.toString(),
                    ResearchTypeId.LegalPipelineSpecialInterestList.toString(),
                    ResearchTypeId.FrsBespokeEdge.toString(),
                    ResearchTypeId.FrsBespokeAdminViewer.toString(),
                    ResearchTypeId.FrsDiagnosticReport.toString(),
                ]),
                userEntitlements,
            );
        },
    },
    Scores: {
        name: PageNames.Scores,
        href: PageNamesToRoutePaths[PageNames.Scores],
        type: 'link',
        icon: ScoresIcon,
        length: 52,
        shouldHideItem: (userEntitlements?: UserEntitlementsResponseData) => {
            const userEntitlementsList: (UserEntitlement | undefined)[] = getAllUserEntitlements(userEntitlements);

            const scoresIDSet = new Set([
                ScoresId.NAEarningsScores.toString(),
                ScoresId.GlobalEarningsScores.toString(),
                ScoresId.NACashFlowScores.toString(),
            ]);

            const scoresLidSet = new Set([LookupItems.ScoreTypeCashFlow, LookupItems.ScoreTypeEarningsScore]);

            return !(
                userEntitlementsList.length > 0 &&
                userEntitlementsList.some(
                    (userEntitlement) =>
                        scoresLidSet.has(userEntitlement?.item_lid || -1) &&
                        scoresIDSet.has(userEntitlement?.value.toString() || ''),
                )
            );
        },
    },
    LegalEdge: {
        name: PageNames.LegalEdge,
        href: PageNamesToRoutePaths[PageNames.LegalEdge],
        type: 'tab',
        icon: LegalEdgeIcon,
        shouldHideItem: (userEntitlements?: UserEntitlementsResponseData) => {
            return checkUserEntitlements(
                new Set([
                    ResearchTypeId.LegalReport.toString(),
                    ResearchTypeId.LegalAlert.toString(),
                    ResearchTypeId.LegalEducational.toString(),
                    ResearchTypeId.LegalPipeline.toString(),
                    ResearchTypeId.LegalPipelineSpecialInterestList.toString(),
                ]),
                userEntitlements,
            );
        },
        popup: {
            title: 'Legal Edge',
            description:
                'Stay ahead of legal catalysts and potential mispricings stemming from major patent disputes, litigation, crises, and regulatory events. Our industry-leading legal research empowers you to make informed investment and risk management decisions with confidence.',
            links_title: 'Links',
            links: [
                {
                    icon: LegalEdgeIcon,
                    name: PageNames.LegalEdge,
                    href: PageNamesToRoutePaths[PageNames.LegalEdge],
                },
                {
                    icon: Analyst,
                    name: 'Analyst Directory',
                    href: PageNamesToRoutePaths[PageNames.LegalEdgeAnalystDirectory],
                },
            ],
        },
        length: 84,
    },
    Bespoke: {
        name: PageNames.Bespoke,
        href: PageNamesToRoutePaths[PageNames.Bespoke],
        type: 'tab',
        icon: BespokeIcon,
        popup: {
            title: 'Bespoke Solutions',
            description:
                'Our Bespoke Research Team offers tailored investment insights, due diligence, and analysis for your projects. From focused reviews to in-depth forensic financial analysis, our proprietary approach identifies and mitigates financial risks, providing you with exceptional support.',
            links_title: 'Links',
            links: [
                {
                    icon: BespokeServices,
                    name: PageNames.BespokeServices,
                    href: PageNamesToRoutePaths[PageNames.BespokeServices],
                },
                {
                    icon: BespokeEdge,
                    name: PageNames.BespokeEdge,
                    href: PageNamesToRoutePaths[PageNames.BespokeEdge],
                    shouldHideLink: (userEntitlements?: UserEntitlementsResponseData) => {
                        const userEntitlementsList: (UserEntitlement | undefined)[] =
                            getAllUserEntitlements(userEntitlements);

                        return !(
                            userEntitlementsList.length > 0 &&
                            userEntitlementsList.some(
                                (entitlement) =>
                                    entitlement?.item_lid === LookupItems.ResearchTypeId &&
                                    entitlement.value.toString() === ResearchTypeId.FrsBespokeEdge.toString(),
                            )
                        );
                    },
                },
                {
                    icon: BespokeAdmin,
                    name: PageNames.BespokeAdmin,
                    href: PageNamesToRoutePaths[PageNames.BespokeAdmin],
                    shouldHideLink: (userEntitlements?: UserEntitlementsResponseData) => {
                        let hide = true;
                        userEntitlements?.packages?.forEach((pckg) => {
                            if (pckg.entitlements) {
                                if (
                                    pckg.entitlements.find(
                                        (ent) =>
                                            ent.item_lid === LookupItems.ResearchTypeId &&
                                            ent.value.toString() === ResearchTypeId.FrsBespokeAdminViewer.toString(),
                                    )
                                ) {
                                    hide = false;
                                    return hide;
                                }
                            }
                        });
                        return hide;
                    },
                },
            ],
        },
        length: 64,
    },
    Watchlist: {
        name: 'Watchlist',
        href: PageNamesToRoutePaths[PageNames.Watchlists],
        type: 'link',
        icon: WatchlistIcon,
        length: 71,
    },
};
