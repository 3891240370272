import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, {
    SeriesOptionsType,
} from 'highcharts/highstock';
import { Chart } from './CFRAChart';

export function CFRAStockChart({
    setChartRef,
    chartRef,
    theme,
    data
}: {
    data: Array<Array<number>>
    setChartRef: (value: any) => void;
    chartRef?: React.RefObject<HighchartsReact.RefObject>;
    theme?: any;
}) {

    const minTimestamp = Math.min(...data.map(d => d[0]));
    const maxTimestamp = Math.max(...data.map(d => d[0]));

    const series: Array<SeriesOptionsType> = [
        {
            type: 'line',
            data: data.sort((a, b) => a[0] - b[0]),
            tooltip: {
                valueDecimals: 2
            },
            color: '#007EF4',
            name: 'Close Price'
        }
    ];

    const options: Highcharts.Options = {
        exporting: {
            enabled: false
        },
        rangeSelector: {
            inputPosition: {
                align: 'left',  // Moves date inputs to the left
            },
            buttonPosition: {
                align: 'right', // Moves range buttons to the right
            },
            buttonTheme: {
                fill: 'none',
                r: 10,
                width: 37,
                style: {
                    fontFamily: fontFamilies.GraphikMedium,
                    fontSize: '13px',
                    fontWeight: '500',
                    lineHeight: '22px',
                    color: '#7E7E7E'
                },
                states: {
                    select: {
                        fill: '#007EF4',
                        style: {
                            color: 'white'
                        }
                    }
                }
            },
            buttons: [{
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month'
            }, {
                type: 'month',
                count: 3,
                text: '3m',
                title: 'View 3 months'
            }, {
                type: 'month',
                count: 6,
                text: '6m',
                title: 'View 6 months'
            }, {
                type: 'ytd',
                text: 'YTD',
                title: 'View Year To Date'
            }, {
                type: 'year',
                count: 1,
                text: '1y',
                title: 'View 1 Year'
            }],
            labelStyle: {
                display: 'none'
            }
        },
        series,
        xAxis: {
            type: 'datetime',
            ordinal: false,
            min: minTimestamp,
            max: maxTimestamp
        },
        navigator: {
            maskFill: 'rgba(0, 126, 244, 0.1)',
            series: {
                color: '#2CAFFE'
            }
        }
    };

    return <Chart options={options} setChartRef={setChartRef} enableExportMenusAndTitle={false} theme={theme} constructorType={'stockChart'} />;
}
