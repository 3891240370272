import { DefaultCFRASnack, Layout } from '@cfra-nextgen-frontend/shared';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridCard, AgGridCardProps } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import {
    AgGridThemes,
    getExportColumnKeys,
    keepNoLeftPaddingOnMove,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { WatchListColumn } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListUtils';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import {
    TypographyStyle13,
    TypographyStyle14,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { getUserColumnDefs } from '@cfra-nextgen-frontend/shared/src/components/UserPreferences/utils/agGrid';
import { customBreakPointValues } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { AgGridPreferencesEachElement, PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { updateSavedItemByIdConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { createTheme, Grid, ThemeProvider, useMediaQuery } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { useWatchlistData } from './hooks/useWatchlistData';

function StyledGridItem({ children }: { children: React.ReactNode }) {
    return (
        <Grid
            item
            container
            sx={{
                height: '46px',
                alignItems: 'center',
                paddingBottom: '8px',
            }}
            gap='8px'
            xs='auto'>
            {children}
        </Grid>
    );
}

function TextRowVariant1({ boldText, normalText }: { boldText?: string; normalText?: string }) {
    return (
        <Grid container sx={{ justifyContent: 'space-between' }}>
            <StyledGridItem>
                <TypographyStyle13 lineHeight={{ xs: 1 }}>{boldText}</TypographyStyle13>
                <DividerStyle1
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                    })}
                />
                <TypographyStyle14
                    width={{
                        xs: '100%',
                        sm: 'auto',
                    }}
                    lineHeight={{
                        xs: 1,
                    }}>
                    {normalText}
                </TypographyStyle14>
            </StyledGridItem>
        </Grid>
    );
}

export type WatchlistCompaniesProps = {
    selectedWatchlist?: SavedItemTypes;
    view?: string;
    addWatchListStarColumn?: boolean;
    getNumberOfResultsText?: (numberOfResults?: number) => string;
    gridRef?: React.RefObject<AgGridReact>;
    preferencesConfiguration?: AgGridCardProps['preferencesConfiguration'];
    enableSavingUserSortModel?: AgGridCardProps['enableSavingUserSortModel'];
    enableSavingUserColumnsOrder?: AgGridCardProps['enableSavingUserColumnsOrder'];
    enableSavingUserColumnsVisibility?: AgGridCardProps['enableSavingUserColumnsVisibility'];
    enableSavingUserColumnsWidths?: AgGridCardProps['enableSavingUserColumnsWidths'];
    useDisableColumnFlexOnResize?: AgGridCardProps['useDisableColumnFlexOnResize'];
    useColumnWidthsFromColumnDefs?: AgGridCardProps['useColumnWidthsFromColumnDefs'];
};

const gridTheme = [AgGridThemes.GridThemeV2, 'ag-panel-top-position-fixed'];

export function WatchlistCompanies({
    selectedWatchlist,
    view,
    addWatchListStarColumn,
    getNumberOfResultsText,
    gridRef,
    preferencesConfiguration,
    enableSavingUserSortModel,
    enableSavingUserColumnsOrder,
    enableSavingUserColumnsVisibility,
    enableSavingUserColumnsWidths,
    useDisableColumnFlexOnResize,
    useColumnWidthsFromColumnDefs,
}: WatchlistCompaniesProps) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [updatedWatchlistData, setUpdatedWatchlistData] = useState();
    const [removingCompanyData, setRemovingCompanyData] = useState<any>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { secTradingIdsFilter, getWatchlistValueQry } = useWatchlistData({ selectedWatchlist });
    const isMediumDevice = useMediaQuery(`(max-width:${customBreakPointValues.md}px)`);

    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getCompaniesBySecIdQuery = sendSingleRequest(
        {
            securityType: 'research',
            view: view,
            requestBody: secTradingIdsFilter ? getFiltersReqBody(secTradingIdsFilter) : undefined,
            config: {
                enabled: secTradingIdsFilter ? true : false,
            },
        },
        {
            path: 'company/screener',
            apiName: ApiNames.Research,
            requestType: RequestTypes.POST,
            queryKeyFirstElement: 'researchCompanyScreenerQuery',
        },
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const { getUserPreferences, setUserPreferences } = useUserPreferences(
        preferencesConfiguration?.useUserPreferencesProps,
    );

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!getCompaniesBySecIdQuery.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        getCompaniesBySecIdQuery.data?.results?.company?.forEach((comp) => {
            if (comp['recent_research'] && comp['recent_research'].length > 0) {
                comp['recent_research'].forEach((res: any) => {
                    const timestamp = res['recent_research']?.publish_timestamp;
                    if (res['recent_research'] && moment().diff(moment(timestamp), 'days') > 365) {
                        res['recent_research'].title = undefined;
                        res['recent_research'].publish_timestamp = undefined;
                    }
                });
            }
        });
        const result = extractFromScreenerData({
            screenerData: getCompaniesBySecIdQuery.data,
            cardName: selectedWatchlist?.name || 'inst_watchlist',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoLeftPadding: true,
            breakpointsConfig: [
                {
                    from: 0,
                    to: customBreakPointValues.md,
                    isActive: isMediumDevice,
                },
            ],
        });

        result.columnDefs.forEach((colDef) => {
            if (colDef.field === 'recent_research.recent_research.publish_timestamp') {
                // This is added to allow sorting works, because values are stored as an array , aggrid sorting function is not able to access right value using field path
                // ValueGetter will help it to get right value for sorting.
                colDef.valueGetter = (data) =>
                    data.data['recent_research']?.[0]?.['recent_research']?.['publish_timestamp'];
            }
        });

        if (addWatchListStarColumn) {
            result.columnDefs.unshift({
                ...WatchListColumn,
                // the field is required to be used in preferences saving
                field: 'watchlist_star_icon',
                // this is necessary to have width undefined to allow autosize to work
                // when use useColumnWidthsFromColumnDefs parameter in AgGridCard
                width: undefined,
                // instead of having width, we have maxWidth to limit the width of the column
                maxWidth: WatchListColumn.width,
                pinned: false,
                cellClass: ['ag-grid-cfra-watchlist-icons-reverse'],
                tooltipValueGetter: (params: ITooltipParams) => {
                    const isSelected = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.isSelected();
                    if (!isSelected) {
                        return `Remove ${params.data?.company_security?.security_trading?.ticker_symbol} from ${
                            selectedWatchlist?.name || 'watchlist'
                        }`;
                    }
                    return '';
                },
                onCellClicked(event: any) {
                    let node = event.node;
                    let isSelected = node.isSelected() === undefined ? false : node.isSelected();
                    node.setSelected(!isSelected);

                    if (!isSelected) {
                        const value = getWatchlistValueQry.data?.data.value as any;
                        if (value?.values?.length > 0 && node.data?.id) {
                            const updatedList = value.values.filter((val: any) => {
                                if (
                                    node.data?.company_security?.security_trading?.cfra_security_trading_id &&
                                    val.cfra_security_trading_id &&
                                    val.cfra_security_trading_id ===
                                        node.data?.company_security?.security_trading?.cfra_security_trading_id
                                ) {
                                    return false;
                                } else if (
                                    node.data?.company_security?.company?.cfra_company_id &&
                                    val.cfra_company_id &&
                                    val.cfra_company_id === node.data?.company_security?.company?.cfra_company_id
                                ) {
                                    return false;
                                } else {
                                    return true;
                                }
                            });
                            setRemovingCompanyData(node.data);
                            setUpdatedWatchlistData(updatedList);
                        }
                    }
                },
                headerValueGetter: (params) => {
                    const defaultHeaderName = params.colDef.headerName || '';

                    switch (params.location) {
                        case 'columnToolPanel':
                            return 'Star';
                    }

                    return defaultHeaderName;
                },
            });
        }

        if (!preferencesConfiguration) {
            return result;
        }

        const userPreferences = getUserPreferences?.<AgGridPreferencesEachElement>({
            preferenceType: PreferenceType.AgGridPreferences,
            selector: preferencesConfiguration.selectorConfiguration.selector,
        });

        if (!userPreferences) {
            return result;
        }

        return {
            ...result,
            columnDefs: getUserColumnDefs({
                initialColumnDefs: result.columnDefs,
                userPreferences,
            }),
        };
    }, [
        getCompaniesBySecIdQuery,
        selectedWatchlist,
        getWatchlistValueQry,
        addWatchListStarColumn,
        isMediumDevice,
        getUserPreferences,
        preferencesConfiguration,
    ]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const updateWatchListQuery = sendSingleRequest<any>(
        {
            savedItems: selectedWatchlist?.id || -1,
            requestBody: {
                name: selectedWatchlist?.name,
                value: {
                    values: updatedWatchlistData,
                },
            },
            config: {
                enabled: selectedWatchlist && updatedWatchlistData ? true : false,
            },
        },
        updateSavedItemByIdConfig,
    );

    useEffect(() => {
        if (
            !updateWatchListQuery?.isLoading &&
            updateWatchListQuery?.isSuccess &&
            !updateWatchListQuery?.data?.isErroredResponse
        ) {
            setUpdatedWatchlistData(undefined);
            getWatchlistValueQry.refetch().finally(() => {
                if (removingCompanyData) {
                    const snackbarKey = ShowSnack(
                        SnackMessageForm({
                            message: `${
                                removingCompanyData?.company_security?.security_trading?.ticker_symbol ||
                                removingCompanyData?.company_security?.company?.company_name
                            } was removed from ${selectedWatchlist?.name}`,
                            actionBtnText: 'Undo Deletion',
                            onActionBtnClick: () => {
                                const value = getWatchlistValueQry.data?.data.value as any;
                                // Push deleted company back to updatedWatchlist data to fire update query
                                value?.values.push({
                                    cfra_security_trading_id:
                                        removingCompanyData?.company_security?.security_trading
                                            ?.cfra_security_trading_id,
                                    cfra_company_id: removingCompanyData?.company_security?.company?.cfra_company_id,
                                });
                                setUpdatedWatchlistData(value?.values);
                                if (snackbarKey) {
                                    closeSnackbar(snackbarKey);
                                }
                            },
                        }),
                    );
                    setRemovingCompanyData(undefined);
                }
            });
        }
    }, [updateWatchListQuery, getWatchlistValueQry, ShowSnack, closeSnackbar, removingCompanyData, selectedWatchlist]);

    if ((getWatchlistValueQry?.data?.data?.value as any)?.values?.length === 0) {
        return <TextRowVariant1 boldText={selectedWatchlist?.name} normalText={getNumberOfResultsText?.()} />;
    }

    const columnKeys = getExportColumnKeys(columnDefs);

    return selectedWatchlist && getCompaniesBySecIdQuery?.isSuccess ? (
        <>
            <TextRowVariant1
                boldText={selectedWatchlist?.name}
                normalText={getNumberOfResultsText?.(getCompaniesBySecIdQuery?.data?.results?.total)}
            />
            <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
                <ThemeProvider theme={createTheme()}>
                    <AgGridCard
                        labelProps={{ width: '100%' }}
                        showDefaultExportButton={false}
                        ref={gridRef}
                        columnDefs={columnDefs}
                        rowMultiSelectWithClick={true}
                        suppressRowClickSelection={true}
                        customFlexibleColumns={customFlexibleColumns}
                        getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                        labelPanelContainerStyles={{ paddingTop: '36px' }}
                        rowsData={getCompaniesBySecIdQuery?.data?.results.company}
                        gridTheme={gridTheme}
                        defaultCsvExportParams={{
                            columnKeys,
                        }}
                        useDragScroll
                        showSideHorizontalScrollIndicators
                        onColumnMovedGetter={keepNoLeftPaddingOnMove}
                        onColumnVisibleGetter={keepNoLeftPaddingOnMove}
                        autoSizePadding={defaultAutosizePadding}
                        tooltipShowDelay={defaultTooltipShowDelay}
                        autosizeColumnsConfig={{
                            skipHeader: false,
                            skipHasPinnedColumnsCheck: true,
                        }}
                        suppressHeaderMenuButton={false}
                        preferencesConfiguration={preferencesConfiguration}
                        enableSavingUserSortModel={enableSavingUserSortModel}
                        enableSavingUserColumnsOrder={enableSavingUserColumnsOrder}
                        enableSavingUserColumnsVisibility={enableSavingUserColumnsVisibility}
                        enableSavingUserColumnsWidths={enableSavingUserColumnsWidths}
                        useDisableColumnFlexOnResize={useDisableColumnFlexOnResize}
                        useColumnWidthsFromColumnDefs={useColumnWidthsFromColumnDefs}
                        setUserPreferencesOnReset={setUserPreferences}
                    />
                </ThemeProvider>
            </AgGridSelectedRowsContextProvider>
        </>
    ) : getCompaniesBySecIdQuery?.isLoading || updateWatchListQuery?.isLoading ? (
        <div>
            <Layout.Skeleton height='10px' />
        </div>
    ) : (
        <></>
    );
}
