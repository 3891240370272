import { StyledMenuItem } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRARoundedSelect';
import { CFRASelectVariant5 } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRASelectVariant5';
import { TypographyStyle7 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, SelectChangeEvent } from '@mui/material';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

export type BcSelectRef = {
    reset: () => void;
    externalSetSelectedOption: (option: BcSelectOptions) => void;
};

export type BcSelectProps = {
    defaultSelection?: BcSelectOptions;
    onChange: (value: BcSelectOptions) => void;
    onDisabledClick?: (index: number) => void;
};

export enum BcSelectOptions {
    AllConcerns = 'All Concerns',
    BiggestConcerns = 'Biggest Concerns',
    HazardList = 'Hazard List',
}

const OptionComponent = ({ option }: { option: BcSelectOptions }) => {
    let badgeJsx = <></>;

    switch (option) {
        case BcSelectOptions.BiggestConcerns:
            badgeJsx = <BadgeBiggestConcerns tooltipTitle='' />;
            break;
        case BcSelectOptions.HazardList:
            badgeJsx = <BadgeHazardList tooltipTitle='' />;
            break;
    }

    return (
        <Box
            className='option-component'
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <TypographyStyle7 paddingRight='12px'>{option}</TypographyStyle7>
            {badgeJsx}
        </Box>
    );
};

export const BcSelect = forwardRef<BcSelectRef, BcSelectProps>(
    ({ defaultSelection = BcSelectOptions.AllConcerns, onChange, onDisabledClick }, ref) => {
        const [selectedOption, setSelectedOption] = useState<BcSelectOptions>();

        useEffect(() => {
            setSelectedOption(defaultSelection);
        }, [defaultSelection]);

        const handleChange = useCallback(
            (value: BcSelectOptions) => {
                setSelectedOption(value);
                onChange(value);
            },
            [onChange],
        );

        useImperativeHandle(ref, () => ({
            reset: () => {
                handleChange(defaultSelection);
            },
            externalSetSelectedOption: (option: BcSelectOptions) => {
                setSelectedOption(option);
            },
        }));

        return (
            <CFRASelectVariant5
                key='bcSelect'
                selectItems={Object.values(BcSelectOptions).map((option) => {
                    return {
                        menuItem: (
                            <StyledMenuItem value={option} key={option}>
                                <OptionComponent option={option} />
                            </StyledMenuItem>
                        ),
                    };
                })}
                handleSelectionChange={(e: SelectChangeEvent<unknown>) =>
                    handleChange(e.target.value as BcSelectOptions)
                }
                currentSelection={selectedOption}
                width={198}
            />
        );
    },
);
