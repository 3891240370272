import { ToggleVariant2 } from '@cfra-nextgen-frontend/shared/src/components/Toggle/ToggleVariant2';

export enum ConcernStateOptions {
    Active = 'Active',
    Removed = 'Removed',
}

interface ConcernStateToggleProps {
    selectedOption: ConcernStateOptions;
    onChange: (option: ConcernStateOptions) => void;
}

export function ConcernStateToggle({ selectedOption, onChange }: ConcernStateToggleProps) {
    const handleChange = (index: number) => {
        const option = Object.values(ConcernStateOptions)[index];
        onChange(option);
    };

    return (
        <ToggleVariant2
            options={Object.values(ConcernStateOptions).map((option) => {
                return { value: option };
            })}
            onChange={handleChange}
            defaultSelectionIndex={Object.values(ConcernStateOptions).indexOf(selectedOption)}
        />
    );
}
