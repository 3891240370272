import React, { useState, useCallback, ComponentProps } from 'react';
import { useLinkGetter, UseLinkGetterOutputProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { ItemVariant2 } from './ItemVariant2';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { LockIconComponent } from './LockIconComponent';

export interface ItemVariant6Props<T> {
    firstRowItems: Array<string | undefined>;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    secondRowText: string;
    thirdRowText: string | null;
    useLinkGetterParams: Parameters<typeof useLinkGetter<T>>;
    hasModalFeature?: boolean;
    setThirdAreaRef?: (el: HTMLDivElement | null) => void;
    handleOuterSetRequestParamsProps?: (
        setRequestParamsProps: UseLinkGetterOutputProps<T>['setRequestParamsProps'],
    ) => void;
    externalOnClick?: () => void;
    hasLock?: boolean;
    ModalComponent?: ComponentProps<typeof LockIconComponent>['ModalComponent'];
}

export function ItemVariant6<T>({
    firstRowItems,
    icon,
    secondRowText,
    thirdRowText,
    useLinkGetterParams,
    hasModalFeature,
    setThirdAreaRef,
    handleOuterSetRequestParamsProps,
    externalOnClick,
    hasLock = false,
    ModalComponent,
}: ItemVariant6Props<T>) {
    const [modalOpen, setModalOpen] = useState(false);
    const [link, setLink] = useState('');

    const handleOpenModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const _handleOuterSetRequestParamsProps = useCallback(
        (setRequestParamsProps: UseLinkGetterOutputProps<T>['setRequestParamsProps']) => {
            if (hasModalFeature) {
                handleOpenModal();
            }

            handleOuterSetRequestParamsProps?.(setRequestParamsProps);
        },
        [handleOuterSetRequestParamsProps, hasModalFeature, handleOpenModal],
    );

    return (
        <>
            <ItemVariant2<T>
                firstRowItems={firstRowItems}
                secondRowText={secondRowText}
                thirdRowText={thirdRowText}
                icon={icon}
                useLinkGetterParams={useLinkGetterParams}
                handleOuterSetRequestParamsProps={_handleOuterSetRequestParamsProps}
                externalOnClick={externalOnClick}
                setLink={setLink}
                hasLock={hasLock}
                lockModalDisablePortal={true} //in global search, when the entitlement modal is closed, we want the popper to remain shown
                ModalComponent={ModalComponent}
            />
            {hasModalFeature && link && !hasLock && (
                <ETFModal
                    date={firstRowItems[0]}
                    getOpenComponent={() => <></>}
                    title={secondRowText}
                    modalBoxStyles={{
                        display: 'block',
                        maxWidth: '1400px',
                        maxHeight: 'none',
                        overflowY: 'hidden',
                    }}
                    descriptionStyle={{
                        boxShadow: 'none',
                    }}
                    titleStyle={{
                        boxShadow: 'none',
                        fontSize: '18px',
                        color: '#002B5A',
                        lineHeight: '1.56em',
                    }}
                    dateStyle={{
                        boxShadow: 'none',
                        color: '#A09F9F',
                        fontSize: '12px',
                        paddingBottom: '0',
                        lineHeight: '1.5em',
                    }}
                    zIndex={5000}
                    externalOpenModal={modalOpen}
                    keepContentOnClose={false}
                    callbackOnClose={handleCloseModal}
                    ref={setThirdAreaRef}
                    htmlContentUrl={link}
                />
            )}
        </>
    );
}
