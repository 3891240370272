import { Box, Button, SxProps, Theme, ThemeProvider } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

type Option = { value: string; disabled?: boolean };

export type ToggleProps = {
    defaultSelectionIndex?: number;
    options: Array<Option>;
    onChange: (index: number) => void;
    onDisabledClick?: (index: number) => void;
    theme?: Theme;
    getOptionSx?: (option: Option, index: number, selectedOption?: number) => SxProps<Theme>;
    sx?: SxProps<Theme>;
};

export type ToggleRef = {
    resetToggle: () => void;
    externalSetSelectedOptionIndex: (index: number) => void;
};

export const Toggle = forwardRef<ToggleRef, ToggleProps>(
    ({ defaultSelectionIndex = 0, options, onChange, onDisabledClick, theme, getOptionSx, sx }, ref) => {
        const [selectedOption, setSelectedOption] = useState<number>();

        useEffect(() => {
            // set it post component is built, to make sure it has latest value.
            setSelectedOption(defaultSelectionIndex);
        }, [defaultSelectionIndex]);

        const handleToggle = useCallback(
            (index: number) => {
                setSelectedOption(index);
                onChange(index);
            },
            [onChange],
        );

        useImperativeHandle(ref, () => ({
            resetToggle: () => {
                handleToggle(defaultSelectionIndex);
            },
            externalSetSelectedOptionIndex: (index: number) => {
                setSelectedOption(index);
            },
        }));

        const result = (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={sx}>
                {options.map((option, index) => (
                    <Button
                        key={index}
                        variant={selectedOption === index ? 'contained' : 'outlined'}
                        onClick={() => {
                            if (!option.disabled) {
                                handleToggle(index);
                            } else {
                                onDisabledClick?.(index);
                            }
                        }}
                        sx={getOptionSx?.(option, index, selectedOption)}>
                        <span style={{ fontSize: '13.5px' }}>{option.value}</span>
                    </Button>
                ))}
            </Box>
        );

        return theme ? <ThemeProvider theme={theme}> {result}</ThemeProvider> : result;
    },
);
