import { getDateTimeUtcNow, standardDateFormat } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BcSelect, BcSelectOptions } from './BcSelect';
import { ConcernStateOptions, ConcernStateToggle } from './ConcernStateToggle';
import { RegionSelect, RegionSelectOptions } from './RegionSelect';

export type RequiredFiltersData = {
    searchByParams: SearchByParams;
    dateColumnId: string;
};

type FiltersProps = {
    setRequiredFiltersData: React.Dispatch<React.SetStateAction<RequiredFiltersData | undefined>>;
    setFiltersState: React.Dispatch<React.SetStateAction<Parameters<typeof getRiskMonitorHomeReqBody>[0] | undefined>>;
    userSortModel?: SearchByParams;
};

const defaultConcernStateOption = ConcernStateOptions.Active;
const defaultBcOption = BcSelectOptions.AllConcerns;

export const additionDateColumns = [
    'frs_biggest_concerns_list.addition_date',
    'frs_hazard_list.addition_date',
    'frs_concerns_last_addition_date',
];

export const removalDateColumns = [
    'frs_biggest_concerns_list.removal_date',
    'frs_hazard_list.removal_date',
    'frs_concerns_last_removal_date',
];

export const dateColumns = [...additionDateColumns, ...removalDateColumns];

export function getRiskMonitorHomeReqBody({
    selectedConcernStateOption,
    selectedBcOption,
    selectedRegionParams,
    userSortModel
}: {
    selectedConcernStateOption: ConcernStateOptions;
    selectedBcOption: BcSelectOptions;
    selectedRegionParams: {
        regions: string[];
        selectedOption: RegionSelectOptions;
    };
    userSortModel?: SearchByParams
}): RequiredFiltersData {
    // create filters object with region filter
    const filters: Array<Record<string, any>> = [
        {
            region_lids__forensic: {
                values: selectedRegionParams.regions,
            },
        },
    ];

    const searchByParams: SearchByParams = {
        sortDirection: 'desc',
    };

    const dateNow = getDateTimeUtcNow(standardDateFormat);
    const date12MonthAgo = moment.utc().subtract(12, 'months').format(standardDateFormat);

    if (
        selectedConcernStateOption === ConcernStateOptions.Active &&
        selectedBcOption === BcSelectOptions.BiggestConcerns
    ) {
        // all currently active bc companies
        filters.push({
            'frs_biggest_concerns_list.is_biggest_concern': {
                values: [true],
            },
        });

        searchByParams.orderBy = 'frs_biggest_concerns_list.addition_date';
    }

    if (selectedConcernStateOption === ConcernStateOptions.Active && selectedBcOption === BcSelectOptions.HazardList) {
        // all currently active hl companies
        filters.push({
            'frs_hazard_list.is_biggest_concern': {
                values: [true],
            },
        });
        filters.push({
            'frs_biggest_concerns_list.is_biggest_concern': {
                values: [false, null],
            },
        });

        searchByParams.orderBy = 'frs_hazard_list.addition_date';
    }

    if (selectedConcernStateOption === ConcernStateOptions.Active && selectedBcOption === BcSelectOptions.AllConcerns) {
        // all currently active bc or hl companies
        filters.push({
            $or: [
                {
                    'frs_biggest_concerns_list.is_biggest_concern': {
                        values: [true],
                    },
                },
                {
                    'frs_hazard_list.is_biggest_concern': {
                        values: [true],
                    },
                },
            ],
        });
        searchByParams.orderBy = 'frs_concerns_last_addition_date';
    }

    if (selectedConcernStateOption === ConcernStateOptions.Removed) {
        // show removed bc and lh companies in the last 12 months
        filters.push({
            frs_concerns_last_removal_date: {
                values: [date12MonthAgo, dateNow],
            },
        });
    }

    if (
        selectedConcernStateOption === ConcernStateOptions.Removed &&
        selectedBcOption === BcSelectOptions.BiggestConcerns
    ) {
        // all removed bc companies
        filters.push({
            'frs_biggest_concerns_list.is_biggest_concern': {
                values: [false],
            },
        });
        filters.push({
            frs_concerns_last_concern_type: {
                values: [BcSelectOptions.BiggestConcerns],
            },
        });

        searchByParams.orderBy = 'frs_biggest_concerns_list.removal_date';
    }

    if (selectedConcernStateOption === ConcernStateOptions.Removed && selectedBcOption === BcSelectOptions.HazardList) {
        // all removed hl companies
        filters.push({
            'frs_hazard_list.is_biggest_concern': {
                values: [false],
            },
        });
        filters.push({
            frs_concerns_last_concern_type: {
                values: [BcSelectOptions.HazardList],
            },
        });

        searchByParams.orderBy = 'frs_hazard_list.removal_date';
    }

    if (
        selectedConcernStateOption === ConcernStateOptions.Removed &&
        selectedBcOption === BcSelectOptions.AllConcerns
    ) {
        // all removed bc or hl companies
        filters.push({
            $or: [
                {
                    $and: [
                        {
                            'frs_biggest_concerns_list.is_biggest_concern': {
                                values: [false],
                            },
                        },
                        {
                            frs_concerns_last_concern_type: {
                                values: [BcSelectOptions.BiggestConcerns],
                            },
                        },
                    ],
                },
                {
                    $and: [
                        {
                            'frs_hazard_list.is_biggest_concern': {
                                values: [false],
                            },
                        },
                        {
                            frs_concerns_last_concern_type: {
                                values: [BcSelectOptions.HazardList],
                            },
                        },
                    ],
                },
            ],
        });
        searchByParams.orderBy = 'frs_concerns_last_removal_date';
    }

    const dateColumnId = searchByParams.orderBy || '';

    if (userSortModel) {
        searchByParams.orderBy = userSortModel.orderBy
        searchByParams.sortDirection = userSortModel.sortDirection;
    }

    return {
        searchByParams: {
            ...searchByParams,
            requestBody: {
                filters: {
                    values: {
                        $and: filters,
                    },
                },
            },
        },
        dateColumnId,
    };
}

export function Filters({ setRequiredFiltersData, setFiltersState, userSortModel }: FiltersProps) {
    const [selectedConcernStateOption, setSelectedConcernStateOption] =
        useState<ConcernStateOptions>(defaultConcernStateOption);
    const [selectedBcOption, setSelectedBcOption] = useState<BcSelectOptions>(defaultBcOption);
    const [selectedRegionParams, setSelectedRegionProps] =
        useState<Parameters<typeof getRiskMonitorHomeReqBody>[0]['selectedRegionParams']>();

    useEffect(() => {
        setRequiredFiltersData((previousValue) => {
            if (!selectedConcernStateOption || !selectedBcOption || !selectedRegionParams) {
                return previousValue;
            }

            const newValue = getRiskMonitorHomeReqBody({
                selectedConcernStateOption,
                selectedBcOption,
                selectedRegionParams,
                userSortModel,
            });

            if (JSON.stringify(previousValue) === JSON.stringify(newValue)) {
                return previousValue;
            }

            return newValue;
        });

        setFiltersState((previousValue) => {
            if (!selectedConcernStateOption || !selectedBcOption || !selectedRegionParams) {
                return previousValue;
            }

            const newValue = { selectedConcernStateOption, selectedBcOption, selectedRegionParams };

            if (JSON.stringify(previousValue) === JSON.stringify(newValue)) {
                return previousValue;
            }

            return newValue;
        });
    }, [
        setRequiredFiltersData,
        selectedConcernStateOption,
        selectedBcOption,
        selectedRegionParams,
        setFiltersState,
        userSortModel
    ]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                gap: '6px',
                paddingBottom: '0px',
            }}>
            <ConcernStateToggle
                selectedOption={ConcernStateOptions.Active}
                onChange={(value) => setSelectedConcernStateOption(value)}
            />
            <BcSelect onChange={(option) => setSelectedBcOption(option)} />
            <RegionSelect onChange={(regions, selectedOption) => setSelectedRegionProps({ regions, selectedOption })} />
        </Box>
    );
}
