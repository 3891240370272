import React, { useEffect } from 'react';

type PageProps = AnalyticsPageProps & {
    children?: React.ReactNode;
};

export const Page = ({ children, ...props }: PageProps) => {
    useEffect(() => {
        globalThis.analytics?.registerPageView?.(props);
    }, [props]);

    return <>{children}</>;
};
