import { Footer } from '@cfra-nextgen-frontend/shared/src/components/Footer';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader/PageWithComponentInHeader';
import { Navigate, Route, Routes } from 'react-router-dom';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';
import { ContactUs } from 'features/contactUs/components/ContactUs';
import { Page } from 'analytics/Page';
import { PageNames } from 'utils/enums';

export function ContactUsRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                element={
                    <>
                        <PageWithComponentInHeader
                            pageContainerSx={{
                                maxWidth: '100%',
                                paddingLeft: horizontalPaddingInSu,
                                paddingRight: horizontalPaddingInSu,
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: 'unset',
                                '@media (max-width: 768.5px)': {
                                    paddingLeft: '14px',
                                    paddingRight: '14px',
                                },
                            }}
                            tabContainerSx={{
                                paddingTop: horizontalPaddingInSu,
                                paddingBottom: horizontalPaddingInSu,
                                paddingLeft: 0,
                                paddingRight: 0,
                                maxWidth: maxPageWidthInPx,
                                boxSizing: 'content-box',
                                backgroundColor: 'unset',
                                width: '100%',
                                '@media (max-width: 768.5px)': {
                                    paddingTop: '14px',
                                    paddingBottom: '14px',
                                },
                            }}
                        />
                        <Footer />
                    </>
                }>
                <Route
                    path='/'
                    element={
                        <Page name={PageNames.ContactUs}>
                            <ContactUs />
                        </Page>
                    }
                />
            </Route>
        </Routes>
    );
}
