import { StyledTabV2 } from '@cfra-nextgen-frontend/cfra-institutional/src/features/home/components/StyledTab';
import { Layout } from '@cfra-nextgen-frontend/shared';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { ScreenerResearchAnalystData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle16,
    TypographyStyle27,
    TypographyStyle31,
    TypographyStyle7,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { AnalystGroupLid, ApiNames, fontWeights, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled, SxProps } from '@mui/material';
import { StyledTabsV2 } from 'features/home/components/StyledTabs';
import { useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useLocation } from 'react-router-dom';
import { sendSingleRequest } from 'utils/api';
import { AnalystProfileDetails } from './AnalystProfileDetails';
import { ProfilePicture } from './ProfileAvatar';

const tabList: Array<{ label: string; view: string; filters: { [key: string]: any } }> = [
    {
        label: 'All',
        view: 'profile_list',
        filters: {
            'platinum.analyst_group.analyst_group_lid': {
                values: [AnalystGroupLid.AccountingLens, AnalystGroupLid.LegalEdge],
            },
        },
    },
    {
        label: 'Accounting',
        view: 'profile_list',
        filters: {
            'platinum.analyst_group.analyst_group_lid': { values: [AnalystGroupLid.AccountingLens] },
        },
    },
    {
        label: 'Legal Edge',
        view: 'profile_list',
        filters: {
            'platinum.analyst_group.analyst_group_lid': { values: [AnalystGroupLid.LegalEdge] },
        },
    },
];

const ListItemBox = styled(Box)(() => ({
    paddingLeft: '48px',
    paddingRight: '6px',
}));

const containerSx: SxProps = {
    margin: 0,
    borderRadius: '8px',
    height: 'fit-content',
    padding: '24px 0',
    '@media (max-width: 768.5px)': {
        padding: '12px',
    },
};

const headerSx: SxProps = {
    padding: '0 24px',
    '@media (max-width: 768.5px)': {
        padding: '0 12px',
    },
};

const tabPanelSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    mt: '-1px',
    pt: '10px',
    borderTop: 'solid 1px #E4E8F4',
    width: '100%',
    minHeight: '300px',
};

const listContainerFrameSx: SxProps = {
    position: 'relative',
    minWidth: '300px',
    mb: '10px',
};

const listContainerSx: SxProps = {
    ...scrollbarThemeV3,
    overflow: 'hidden auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
};

const avatarContainerSx: SxProps = {
    width: '96px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const listItemSx: SxProps = {
    display: 'flex',
    padding: '22px 0',
    cursor: 'pointer',
};

function determineTabFromPath(pathname = '') {
    if (pathname.startsWith('/accounting-lens')) {
        return AnalystGroupLid.AccountingLens;
    } else if (pathname.startsWith('/legal-edge')) {
        return AnalystGroupLid.LegalEdge;
    }
    return 0;
}

const defaultTitle = "FRS Analyst"

export const AnalystDirectory = () => {
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(determineTabFromPath(location.pathname));
    const [selectedAnalystId, setSelectedAnalystId] = useState<number | undefined>();
    const [selectedAnalystIdx, setSelectedAnalystIdx] = useState<number | undefined>();

    const handleTabChange = (_: React.SyntheticEvent, v: number) => {
        setSelectedTab(v);
        setSelectedAnalystId(undefined);
        setSelectedAnalystIdx(undefined);
    };

    const getAnalystProfileListQuery = sendSingleRequest?.(
        {
            path: 'analyst/screener',
            securityType: 'analyst',
            view: 'profile_list',
            requestBody: {
                filters: {
                    values: {
                        ...tabList[selectedTab].filters,
                    },
                },
            },
            config: {
                enabled: typeof selectedTab === 'number',
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'analyst/screener',
            queryKeyFirstElement: 'getAnalystProfileListQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchAnalystData>;

    const analysts = useMemo(() => {
        return getAnalystProfileListQuery?.data?.results?.analyst || [];
    }, [getAnalystProfileListQuery?.data?.results?.analyst]);

    useEffect(() => {
        if (
            analysts.length > 0 &&
            typeof selectedAnalystId !== 'number' &&
            typeof analysts[0]?.analyst?.analyst_id === 'number'
        ) {
            setSelectedAnalystId(analysts[0].analyst.analyst_id);
            setSelectedAnalystIdx(0);
        }
    }, [analysts, selectedAnalystId]);

    const handleListItemClick = (item: { [x: string]: any; analyst?: any }, idx: number) => {
        setSelectedAnalystId(item.analyst.analyst_id);
        setSelectedAnalystIdx(idx);
    };

    return (
        <Grid container display='block'>
            <ETFCard containerStyles={containerSx}>
                <Grid width='100%'>
                    <Box sx={headerSx} mb='18px'>
                        <TypographyStyle16
                            variant='h3'
                            sx={{ color: '#002B5A', mb: '12px', fontWeight: fontWeights.Medium }}>
                            Meet Our Analysts
                        </TypographyStyle16>
                        <TypographyStyle31 maxWidth='700px'>
                            A passionate group of analysts and market leaders dedicated to providing in-depth financial
                            insights and strategic market analysis. Our team combines expertise and innovation to
                            deliver actionable intelligence for informed investment decisions.
                        </TypographyStyle31>
                    </Box>

                    <Grid item xs={12} p='0 24px'>
                        <StyledTabsV2
                            value={selectedTab}
                            variant='scrollable'
                            allowScrollButtonsMobile
                            TabIndicatorProps={{ style: { display: 'none' } }}
                            onChange={handleTabChange}>
                            {tabList.map((tab, idx) => (
                                <StyledTabV2 key={idx} label={tab.label} {...Layout.a11yProps(idx)} value={idx} />
                            ))}
                        </StyledTabsV2>
                    </Grid>

                    <Grid item xs={12} sx={tabPanelSx}>
                        {getAnalystProfileListQuery?.isLoading ? (
                            <Layout.Skeleton height={10} sx={{ m: '100px 0 24px 0', width: '90%' }} />
                        ) : (
                            <Box display='flex' justifyContent='space-between' width='100%'>
                                <Box sx={listContainerFrameSx}>
                                    <Box sx={listContainerSx}>
                                        {analysts.map((item, index) => (
                                            <ListItemBox
                                                key={item?.analyst?.analyst_id}
                                                bgcolor={
                                                    selectedAnalystId === item?.analyst?.analyst_id ? '#F3F5FC' : '#FFF'
                                                }>
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        ...listItemSx,
                                                        borderBottom:
                                                            selectedAnalystIdx === index ||
                                                            selectedAnalystIdx === index + 1 ||
                                                            index === analysts.length - 1
                                                                ? 'none'
                                                                : '1.5px dotted #CCCCCC',
                                                    }}
                                                    onClick={() => handleListItemClick(item, index)}>
                                                    <Box sx={avatarContainerSx}>
                                                        <ProfilePicture
                                                            width='86.62px'
                                                            height='96px'
                                                            avatarDim='96px'
                                                            imageUrl={item?.analyst?.profile_picture_s3_url}
                                                            firstName={item?.analyst?.first_name}
                                                            lastName={item?.analyst?.last_name}
                                                        />
                                                    </Box>
                                                    <Box pt='6px' pl='18px'>
                                                        <TypographyStyle27 lineHeight='24.2px'>
                                                            {item?.analyst?.first_name}
                                                        </TypographyStyle27>
                                                        <TypographyStyle27 lineHeight='24.2px'>
                                                            {item?.analyst?.last_name}
                                                        </TypographyStyle27>
                                                        <TypographyStyle7 lineHeight='20px' pt='6px'>
                                                            {item?.analyst?.title || defaultTitle}
                                                        </TypographyStyle7>
                                                    </Box>
                                                </Box>
                                            </ListItemBox>
                                        ))}
                                    </Box>
                                </Box>
                                {selectedAnalystId && <AnalystProfileDetails analystId={selectedAnalystId} />}
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </ETFCard>
        </Grid>
    );
};
