import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { ScoresHome } from 'features/scores/components/ScoresHome';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Page } from 'analytics/Page';
import { PageNames } from 'utils/enums';

export function ScoresRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                path='/'
                element={
                    <Page name={PageNames.Scores}>
                        <PageWrapper>
                            <ScoresHome />
                        </PageWrapper>
                    </Page>
                }
            />
        </Routes>
    );
}
