import {
    AccordionsSection,
    AccordionsSectionProps,
} from '@cfra-nextgen-frontend/shared/src/components/ControlledAccordions/AccordionsSection';
import { ClearButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ClearButton';
import { autocompleteThemeVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Form/themes/autocomplete';
import { Components, FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { UseFiltersFormInputProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerFormCheckboxList } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormCheckboxList';
import { ScreenerFormVirtualizeAutocomplete } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormVirtualizeAutocomplete';
import {
    SearchInputVariant1,
    SearchInputVariant1Ref,
} from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1';
import { SearchInputProps } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/types';
import { ToggleVariant1, ToggleVariant1Props, ToggleVariant1Ref } from '@cfra-nextgen-frontend/shared/src/components/Toggle/ToggleVariant1';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { PageTheme } from 'components/themes/theme';
import { useMemo } from 'react';
import { filterNameToIcon } from 'utils/mappings';
import { Control, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import FormRadioGroup from '@cfra-nextgen-frontend/shared/src/components/Form/FormRadioGroup';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { commonCustomBreakpointsTheme } from 'components/themes/customBreakpointsTheme';

type FilterJSXArgs = {
    submitHandler: () => void;
    control: Control;
    filtersData: FiltersData;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    validate: (fieldName: string) => Promise<boolean | undefined>;
    onChipClearAllClickRef: React.RefObject<() => void>;
    toggleRef?: React.RefObject<ToggleVariant1Ref>;
    toggleProps?: ToggleVariant1Props;
    searchInputProps?: SearchInputProps;
};

const customTheme = createTheme({
    components: {
        MuiStack: {
            styleOverrides: {
                root: {
                    width: '290px',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '10px',
                    background: '#FFF',
                    height: '48px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                    height: '42px',
                },
            },
        },
    },
});

const autoCompleteThemeV1 = createTheme(autocompleteThemeVariant1);

export const SearchCompanyFilter = ({ searchInputProps }: { searchInputProps?: SearchInputProps }) => (
    <ThemeProvider theme={createTheme()}>
        <SearchInputVariant1
            customTheme={createTheme(customTheme, {
                components: {
                    MuiStack: {
                        styleOverrides: {
                            root: {
                                width: '251px',
                                paddingLeft: '17px',
                                [autoCompleteThemeV1.breakpoints.down('md')]: {
                                    width: 'auto',
                                },
                            },
                        },
                    },
                    MuiInputBase: {
                        styleOverrides: {
                            input: {
                                color: '#3C3C3C',
                                '::placeholder': {
                                    opacity: 1,
                                },
                            },
                        },
                    },
                },
            })}
            showInput
            showClearButtonOnlyWhenNonEmptyInput
            showSearchIconOnlyWhenEmptyInput
            {...searchInputProps}
        />
    </ThemeProvider>
);

const _getFiltersJSX = ({
    submitHandler,
    control,
    filtersData,
    getValues,
    setValue,
    validate,
    onChipClearAllClickRef,
    toggleRef,
    toggleProps,
    searchInputProps,
}: FilterJSXArgs) => (
    <>
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                gap: '12px',
                paddingBottom: '24px',
            }}>
            <ThemeProvider theme={PageTheme}>
                {toggleProps && <ToggleVariant1 ref={toggleRef} {...toggleProps} />}
            </ThemeProvider>
            <ScreenerFormVirtualizeAutocomplete
                defaultInputLabel={'Scores'}
                useFormLabelWithContainer={false}
                alwaysShowLabel={true}
                highlightOnSelection={true}
                control={control}
                filtersData={filtersData}
                filterMetadataKey={'frs_cash_flow_score_drivers.decile'}
                submitHandler={submitHandler}
                getValues={getValues}
                setValue={setValue}
                validate={validate}
                parentSectionKey={'root'}
                hide={false}
                theme={createTheme(autocompleteThemeVariant1)}
            />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '12px', flexWrap: 'wrap' }}>
            <ScreenerFormVirtualizeAutocomplete
                defaultInputLabel={'Industry'}
                alwaysShowLabel={true}
                highlightOnSelection={true}
                useFormLabelWithContainer={false}
                control={control}
                filtersData={filtersData}
                filterMetadataKey={'company_security.company_sector._lookup_gics_ind_grp_lid.key'}
                submitHandler={submitHandler}
                getValues={getValues}
                setValue={setValue}
                validate={validate}
                parentSectionKey={'root'}
                hide={false}
                theme={createTheme(autocompleteThemeVariant1)}
            />
            <ScreenerFormVirtualizeAutocomplete
                defaultInputLabel={'Region'}
                alwaysShowLabel={true}
                highlightOnSelection={true}
                useFormLabelWithContainer={false}
                control={control}
                filtersData={filtersData}
                filterMetadataKey={'company_security.company.region_country_lid'}
                submitHandler={submitHandler}
                getValues={getValues}
                setValue={setValue}
                validate={validate}
                parentSectionKey={'root'}
                hide={false}
                theme={createTheme(autocompleteThemeVariant1)}
            />
            <SearchCompanyFilter searchInputProps={searchInputProps} />
            <ClearButton onClick={() => onChipClearAllClickRef.current?.()} />
        </Box>
    </>
);

const _getFiltersJSXForMobile = ({
    submitHandler,
    control,
    filtersData,
    getValues,
    setValue,
    validate,
    toggleProps,
}: Omit<FilterJSXArgs, 'onChipClearAllClickRef'>) => {
    const Result = () => {
        const accordionsSectionOptions = useMemo(() => {
            const result: AccordionsSectionProps['options'] = [];

            result.push({
                label: 'Scores Category',
                icon: filterNameToIcon['Scores Category'],
                component: toggleProps && (
                    <FormRadioGroup
                        control={control}
                        defaultValue={toggleProps.defaultSelectionIndex}
                        name={combineIntoFormElementName({
                            componentName: Components.RadioGroup,
                            filterMetadataKey: 'frs_scores_category_dummy',
                        })}
                        options={toggleProps.options.map((option, index) => ({ key: index, value: option.value }))}
                        submitHandler={(data) => {
                            toggleProps.onChange(Number(data));
                        }}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                    />
                ),
            });

            result.push({
                label: 'Scores',
                icon: filterNameToIcon['Scores'],
                component: (
                    <ScreenerFormCheckboxList
                        control={control}
                        component={Components.Autocomplete}
                        filtersData={filtersData}
                        filterMetadataKey={'frs_cash_flow_score_drivers.decile'}
                        submitHandler={submitHandler}
                        theme={commonCustomBreakpointsTheme}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                    />
                ),
            });

            result.push({
                label: 'Industry',
                icon: filterNameToIcon['Industry'],
                component: (
                    <ScreenerFormCheckboxList
                        control={control}
                        component={Components.Autocomplete}
                        filtersData={filtersData}
                        filterMetadataKey={'company_security.company_sector._lookup_gics_ind_grp_lid.key'}
                        submitHandler={submitHandler}
                        theme={commonCustomBreakpointsTheme}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                    />
                ),
            });

            result.push({
                label: 'Region',
                icon: filterNameToIcon['Region'],
                component: (
                    <ScreenerFormCheckboxList
                        control={control}
                        component={Components.Autocomplete}
                        filtersData={filtersData}
                        filterMetadataKey={'company_security.company.region_country_lid'}
                        submitHandler={submitHandler}
                        theme={commonCustomBreakpointsTheme}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                    />
                ),
            });

            return result;
        }, []);

        return <AccordionsSection options={accordionsSectionOptions} />;
    };

    return <Result />;
};

export const getFiltersJsx = ({
    toggleProps: { ref: toggleRef, ...toggleProps },
    searchInputProps,
    isMobileVariant,
}: {
    toggleProps: ToggleVariant1Props & { ref: React.RefObject<ToggleVariant1Ref> };
    searchInputProps: SearchInputProps & { ref: React.RefObject<SearchInputVariant1Ref> };
    isMobileVariant?: boolean;
}) =>
    (({ submitHandler, control, filtersData, getValues, setValue, validate, onChipClearAllClickRef }) => {
        if (!isMobileVariant) {
            return _getFiltersJSX({
                submitHandler,
                control,
                filtersData,
                getValues,
                setValue,
                validate,
                onChipClearAllClickRef,
                toggleRef,
                toggleProps,
                searchInputProps,
            });
        } else {
            return _getFiltersJSXForMobile({
                submitHandler,
                control,
                filtersData,
                getValues,
                setValue,
                validate,
                toggleProps,
            });
        }
    }) as UseFiltersFormInputProps['getFiltersJsx'];
