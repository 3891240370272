import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import {
    PillsRowVariant1,
    PillsRowVariant1Item,
} from '@cfra-nextgen-frontend/shared/src/components/Pill/PillsRowVariant1';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle16,
    TypographyStyle17,
    TypographyStyle18,
    TypographyStyle4,
    TypographyStyle6,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { getEllipsisStyles } from '@cfra-nextgen-frontend/shared/src/components/Typography/utils';
import { useCheckOverflow } from '@cfra-nextgen-frontend/shared/src/hooks/useCheckOverflow';
import { useLinkGetter, UseLinkGetterOutputProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { useTextCutter } from '@cfra-nextgen-frontend/shared/src/hooks/useTextCutter';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Box, Grid, styled, SxProps, useMediaQuery } from '@mui/material';
import React, { ComponentProps, useCallback, useMemo, useState } from 'react';
import { getCursorVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/cursor';
import { LockIconComponent } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/LockIconComponent';

const oneLineEllipsisStyles = getEllipsisStyles(1);

type ItemVariant5Props<T> = {
    topText: string;
    topSubText: string;
    middleTitle: string;
    middleSubText: string;
    bottomTextItems?: Array<string>;
    bottomPillItems: Array<PillsRowVariant1Item>;
    bottomPillItemsThreshold: number;
    innerRef?: React.Ref<HTMLDivElement>;
    maxMiddleSubTextLength: number;
    useLinkGetterParams: Parameters<typeof useLinkGetter<T>>;
    index: number;
    handleOuterSetRequestParamsProps?: (
        setRequestParamsProps: UseLinkGetterOutputProps<T>['setRequestParamsProps'],
    ) => void;
    hasLock?: boolean;
    ModalComponent?: ComponentProps<typeof LockIconComponent>['ModalComponent'];
};

export function ItemVariant5<T>({
    topText,
    topSubText,
    middleTitle,
    middleSubText,
    bottomPillItems,
    bottomPillItemsThreshold,
    innerRef,
    maxMiddleSubTextLength,
    useLinkGetterParams,
    index,
    handleOuterSetRequestParamsProps,
    hasLock = false,
    ModalComponent,
}: ItemVariant5Props<T>) {
    const { setRequestParamsProps, isLoading, isError } = useLinkGetter<T>(...useLinkGetterParams);
    const [isPrimaryArticle] = useState(index === 0);
    const isBelowLg = useMediaQuery(breakpointsTheme.breakpoints.down('md'));
    const [modalOpen, setModalOpen] = useState(false);

    const cursor = useMemo(() => getCursorVariant1(isLoading, isError), [isLoading, isError]);

    const { lastIndex, cutNextWord } = useTextCutter({
        text: middleSubText,
        maxTextLength: maxMiddleSubTextLength,
    });

    const { setContainer } = useCheckOverflow({
        onOverflowCallback: () => cutNextWord(),
    });

    const handleLinkClick = useCallback(() => {
        if (hasLock) {
            if (!modalOpen) setModalOpen(true);
        } else {
            handleOuterSetRequestParamsProps?.(setRequestParamsProps);
        }
    }, [handleOuterSetRequestParamsProps, setRequestParamsProps, hasLock, modalOpen]);

    const linkTextStyle = {
        lineHeight: '1.35',
        '&:hover': {
            color: '#3078B5',
            cursor: cursor,
        },
    };

    const StyledCardHeader = styled(Grid)(({ theme }) => ({
        display: 'flex',
        alignItems: 'baseline',
        gap: '0px 8px',
        margin: '3px 0',
    }));

    function StyledPrimaryTickersCsv(bottomPillItems: any[], sx: SxProps = {}) {
        if (bottomPillItems.length > 0) {
            return (
                <TypographyStyle17
                    component='span'
                    sx={{ fontFamily: fontFamilies.GraphikMedium, fontWeight: fontWeights.Medium, ...sx }}>
                    {'(' + bottomPillItems.map((item) => item.content).join(', ') + ') '}
                </TypographyStyle17>
            );
        }
        return '';
    }

    return (
        <>
            <Grid
                item
                ref={innerRef}
                sx={{
                    display: 'flex',
                    paddingTop: '4px',
                    width: '100%',
                }}>
                <Box width='30px'>
                    <TypographyStyle16 component='div' textAlign='center'>
                        {index + 1}
                    </TypographyStyle16>
                </Box>

                <Box marginLeft='12px' width='calc(100% - 30px)'>
                    <StyledCardHeader container>
                        <Grid item>
                            <TypographyStyle4 sx={{ ...oneLineEllipsisStyles, fontWeight: fontWeights.Medium }}>
                                {topText}
                            </TypographyStyle4>
                        </Grid>

                        {!isBelowLg && <DividerStyle1 sx={{ marginTop: '2px', height: '13px', borderWidth: '1px' }} />}

                        <Grid item>
                            <TypographyStyle18>{topSubText}</TypographyStyle18>
                        </Grid>
                    </StyledCardHeader>

                    {isPrimaryArticle && (
                        <Box ref={setContainer} paddingBottom='5px' onClick={handleLinkClick}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                {hasLock && ModalComponent && (
                                    <LockIconComponent
                                        modalOpen={modalOpen}
                                        setModalOpen={setModalOpen}
                                        ModalComponent={ModalComponent}
                                    />
                                )}
                                <TypographyStyle6
                                    sx={{
                                        ...linkTextStyle,
                                        fontSize: '16px',
                                    }}>
                                    {middleTitle}
                                </TypographyStyle6>
                            </Box>
                            <RenderMiddleText
                                middleSubText={middleSubText}
                                displayMaxLength={lastIndex}
                                onClick={handleLinkClick}
                                sx={{
                                    ...linkTextStyle,
                                    fontWeight: fontWeights.Regular,
                                }}
                            />
                            {bottomPillItems.length > 0 && (
                                <Grid container gap={'5px'} sx={{ paddingTop: '10px' }}>
                                    <PillsRowVariant1 items={bottomPillItems} threshold={bottomPillItemsThreshold} />
                                </Grid>
                            )}
                        </Box>
                    )}
                    {!isPrimaryArticle && (
                        <Box ref={setContainer} paddingBottom='5px' sx={{ display: 'flex' }} onClick={handleLinkClick}>
                            {hasLock && ModalComponent && (
                                <LockIconComponent
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                    ModalComponent={ModalComponent}
                                />
                            )}
                            <RenderMiddleText
                                prefix={StyledPrimaryTickersCsv(bottomPillItems, {
                                    ...linkTextStyle,
                                    lineHeight: '1.33',
                                })}
                                middleSubText={middleTitle}
                                displayMaxLength={lastIndex}
                                sx={{ ...linkTextStyle, lineHeight: '1.33', fontWeight: fontWeights.Regular }}
                            />
                        </Box>
                    )}
                </Box>
            </Grid>
        </>
    );
}

export function RenderMiddleText(props: any) {
    const { prefix, displayMaxLength = 100, middleSubText = '', onClick = () => {}, sx = {} } = props;

    let displayText: string = '';

    if (middleSubText) {
        if (
            displayMaxLength &&
            middleSubText.length > displayMaxLength &&
            middleSubText.lastIndexOf(' ', displayMaxLength) > 0
        ) {
            displayText = middleSubText.substring(0, middleSubText.lastIndexOf(' ', displayMaxLength));
        } else {
            displayText = middleSubText;
        }
    }

    if (middleSubText.length > displayText.length) {
        displayText = `${displayText}...`;
    }

    if (!!!displayText) {
        return <></>;
    }

    return (
        <TypographyStyle17 component='div' onClick={onClick} sx={{ ...sx }}>
            {prefix}
            {displayText}
        </TypographyStyle17>
    );
}
