import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import Box from '@mui/material/Box';
import { Typography } from '@cfra-nextgen-frontend/shared';
import { BarChartWithBigLabels, getTooltipHTML } from '@cfra-nextgen-frontend/shared/src/components/Chart';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { IMetadataFields } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import '../../../components/themes/companyBarChartStyle.scss';

const customYAxisLabelProps = {
    formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
        if (this.value === this.axis.min) {
            return '<div class="xs-pl-30" style="padding-left:50px;display:flex">Less Risk</div>';
        }
        if (this.value === this.axis.max) {
            return '<div style="padding-right:50px;display:flex">More Risk</div>';
        }
    },
    rotation: 0,
    useHTML: true,
    overflow: 'allow',
};

export function BarChart({
    chartTitle,
    data,
    metadataFields,
    parentField,
    theme,
    entitled
}: {
    chartTitle: string;
    data: any;
    metadataFields: Array<IMetadataFields>;
    parentField: string;
    theme: any;
    entitled?: boolean;
}) {
    const [chartRef, setChartRef] = useState<React.RefObject<HighchartsReact.RefObject>>();

    if (entitled && data === undefined) return <></>;

    if (!entitled && data === undefined) { // dummy data
        data = {
            'Goodwill': -0.003191,
            'Inventories': 0.000623,
            'Payables': 0.003214,
            'Receivables': 0.005561
        }
    }

    //sorting keys by value to get all -ve nos together and all +ve values together
    const chartKeys = Object.keys(data)
        .filter((key) => !['date', 'decile'].includes(key))
        .sort((a, b) => data[a] - data[b]);

    const chartLabels = chartKeys.map((key) => {
        if (!entitled) return { labelName: key };

        let field = metadataFields.find((field) => field[parentField + '.' + key]);
        let fieldName = parentField + '.' + key;

        return field
            ? { labelName: field[fieldName].short || field[fieldName].label, fullName: field[fieldName].label }
            : { labelName: 'Not Found' };
    });

    const chartData = chartKeys.map((key) => data[key]);

    function getColumnHeaderFormatter(yAxisColumnName: string) {
        return function (item: any, key: any): string {
            return yAxisColumnName;
        };
    }

    function tooltipFormatter(
        this: Highcharts.TooltipFormatterContextObject & { category?: string; options?: { y: number } },
    ) {
        let tooltipTitle = chartLabels.find((label) => label.labelName === this.category)?.fullName;
        return getTooltipHTML(tooltipTitle, [`<span>${this.options?.y || 0}</span>`]);
    }

    return (
        <Grid container>
            <Grid item sx={{ paddingBottom: '15px' }}>
                <Box>
                    <Typography variant='cardDescriptionTitle' sx={{ fontWeight: 500 }}>
                        {chartTitle}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <BarChartWithBigLabels
                    categories={chartLabels.map((label) => label.labelName)}
                    categoriesData={chartData}
                    setChartRef={setChartRef}
                    chartRef={chartRef}
                    columnHeaderFormatter={getColumnHeaderFormatter('')}
                    tooltipFormatter={tooltipFormatter}
                    theme={theme}
                    customYAxisLabelProps={customYAxisLabelProps}
                />
            </Grid>
        </Grid>
    );
}
