import { ResearchHubHome } from 'features/researchHub/components/ResearchHubHome';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Page } from 'analytics/Page';
import { PageNames } from 'utils/enums';

export function ResearchHubRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                path='/'
                element={
                    <Page name={PageNames.ResearchHub}>
                        <ResearchHubHome />
                    </Page>
                }
            />
        </Routes>
    );
}
