import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { HeroSection } from '@cfra-nextgen-frontend/shared/src/components/HeroSection/HeroSection';
import { Contacts } from './Contacts';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import Background from '@cfra-nextgen-frontend/shared/src/assets/images/ContactUs.png';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { Typography } from '@cfra-nextgen-frontend/shared';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ContactForm } from 'components/ContactForm/ContactForm';

export function ContactUs() {
    const isMobile = useMediaQuery('(min-width:768.5px)');
    const isPhone = useMediaQuery('(max-width:768.5px)');

    return (
        <Grid container style={{ display: 'block' }}>
            {isMobile && (
                <HeroSection
                    preTitle='HELP & SUPPORT'
                    title="We're Here to Help"
                    subtitle='Help & Support'
                    textLines={[
                        'Have questions about our products, services,',
                        'or general inquiries? Our team is ready to assist you.',
                        "Reach out, and we'll respond promptly.",
                    ]}
                    backgroundImage={Background}
                />
            )}
            <ETFCard
                containerStyles={{
                    padding: '24px',
                    borderRadius: '8px',
                    margin: 0,
                    height: 'fit-content',
                }}>
                {isPhone && (
                    <>
                        <Grid
                            sx={{
                                width: '100%',
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: '25px',
                                    left: 0,
                                    width: '100%',
                                    height: '1px',
                                    borderBottom: '2px solid #DDDDDD',
                                    backgroundSize: '5px 1px',
                                },
                            }}>
                            <Typography
                                variant='h3'
                                textAlign='left'
                                style={{
                                    fontFamily: fontFamilies.GraphikSemibold,
                                    fontWeight: 600,
                                    fontSize: '15px',
                                    color: '#002B5A',
                                    paddingBottom: '15px',
                                }}>
                                Contacts
                            </Typography>
                        </Grid>
                    </>
                )}

                <Grid
                    sx={{
                        width: '100%',
                        position: 'relative',
                        paddingBottom: '16px',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: isPhone ? '-25px' : '-170px',
                            left: 0,
                            width: '100%',
                            height: '1px',
                            backgroundImage: 'radial-gradient(circle, #aaa 1px, transparent 1px)',
                            backgroundSize: '5px 1px',
                        },
                    }}
                />
                <Grid>
                    {isMobile && (
                        <Typography
                            variant='h3'
                            textAlign='left'
                            style={{
                                fontFamily: fontFamilies.GraphikMedium,
                                fontWeight: 500,
                                fontSize: '18px',
                                color: '#002B5A',
                            }}>
                            Reach Out!
                        </Typography>
                    )}

                    <Typography
                        variant='h3'
                        textAlign='left'
                        style={{
                            fontFamily: fontFamilies.GraphikSemibold,
                            fontWeight: 600,
                            fontSize: isPhone ? '15px' : '40px',
                            color: '#002B5A',
                        }}>
                        Call Us, or We’ll Call You
                    </Typography>
                    {isMobile && (
                        <Typography
                            style={{
                                color: '#3C3C3C',
                                fontFamily: fontFamilies.GraphikRegular,
                                fontSize: '17px',
                                marginTop: '13px',
                                marginBottom: '21px',
                                lineHeight: '1.6em',
                            }}
                            textAlign='left'>
                            If you prefer, we can contact you. Please use the form below and we will contact you as soon
                            as possible. Please check the phone number listed below and change if necessary. We are here
                            to help.
                        </Typography>
                    )}
                </Grid>
                <Grid
                    sx={{
                        marginTop: '25px',
                        display: 'flex',
                        flexDirection: isPhone ? 'column' : 'row',
                        gap: '36px',
                        width: '100%',
                        alignItems: isPhone ? 'center' : 'unset',
                    }}>
                    <Contacts />
                    <Grid
                        container
                        direction='column'
                        spacing={3}
                        style={{ display: 'block', maxWidth: isPhone ? '377px' : '704px', margin: 0 }}>
                        {isMobile && (
                            <Typography
                                variant='h4'
                                style={{
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    color: '#002B5A',
                                    fontFamily: fontFamilies.GraphikBold,
                                    marginBottom: '36px',
                                    marginTop: '5px',
                                }}>
                                We’re here to help!
                            </Typography>
                        )}
                        <ContactForm />
                    </Grid>
                </Grid>
            </ETFCard>
        </Grid>
    );
}
