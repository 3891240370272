import { ReactComponent as LockIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/entitlement-lock.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { Box } from '@mui/material';
import { useCallback } from 'react';

export type LockModalProps = {
    handleClose: () => void;
    disableModalPortal?: boolean;
    modalOpen: boolean;
    handleCloseModal: () => void;
    onSubmitNotEntitledNewFormData?: Record<string, string>;
};

export const LockIconComponent = ({
    modalOpen,
    setModalOpen,
    disableModalPortal = false,
    ModalComponent,
}: {
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    disableModalPortal?: boolean;
    ModalComponent: (props: LockModalProps) => JSX.Element;
}) => {
    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    return (
        <Box
            component='span'
            sx={{
                marginRight: '5px',
                cursor: 'pointer',
            }}>
            <CustomSvgIcon
                component={LockIcon}
                viewBox='0 0 68 68'
                sx={{
                    '&:hover': {
                        fill: '#007AB9',
                    },
                }}
                onClick={() => setModalOpen(true)}
            />
            <ModalComponent
                handleClose={handleCloseModal}
                disableModalPortal={disableModalPortal}
                modalOpen={modalOpen}
                handleCloseModal={handleCloseModal}
            />
        </Box>
    );
};
