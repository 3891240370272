import {
    InfiniteOptions,
    InfiniteOptionsRef,
} from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import {
    ItemVariant3,
    ItemVariant3Container,
} from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant3';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { SendSingleInfiniteRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { DataItem, ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { GetRequestParamsProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { SxProps } from '@mui/material';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';
import { getScreenerInfiniteOptionsProps } from 'features/home/components/InfiniteOptions/screener';
import moment from 'moment';
import { ComponentProps, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortPrimaryFirst } from 'utils/arrays';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';

type SearchResultsGridViewProps = {
    searchByParams: SearchByParams;
    extendedScreenerInfiniteRequestParamsConfig: Parameters<SendSingleInfiniteRequest>[1];
    gridViewItemContainerStyles?: SxProps;
    handleResultsCount: (pages?: Array<ScreenerResearchData>) => void;
    scrollThresholdPx: number;
    optionsContainerSx?: SxProps;
    useOuterContainerRef?: boolean;
};

export function SearchResultsGridView({
    searchByParams,
    extendedScreenerInfiniteRequestParamsConfig,
    gridViewItemContainerStyles,
    handleResultsCount,
    scrollThresholdPx,
    optionsContainerSx,
    useOuterContainerRef,
}: SearchResultsGridViewProps) {
    const infiniteOptionsRef = useRef<InfiniteOptionsRef>(null);

    const navigate = useNavigate();

    const onPillClickHandler = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    const optionsOuterContainerRef = useRef<HTMLDivElement | null>(null);

    const setOptionsOuterContainerRef = useCallback(
        (node?: HTMLDivElement | null) => {
            if (node === undefined) {
                return;
            }

            optionsOuterContainerRef.current = node;
        },
        [optionsOuterContainerRef],
    );

    const gridView = useMemo(() => {
        return (
            <InfiniteOptions<
                ScreenerResearchData,
                DataItem,
                Omit<ComponentProps<typeof ItemVariant3Container>, 'children'>
            >
                key={`gridView_${searchByParams.size}`}
                ref={infiniteOptionsRef}
                OptionsContainer={ItemVariant3Container}
                optionsContainerProps={{
                    containerStyles: {
                        width: '100%',
                        overflowX: 'hidden', // present horizontal scrollbar on 375px screen width
                        ...optionsContainerSx,
                    },
                    containerWrapperSx: {
                        marginTop: '-18px',
                        marginBottom: '-18px',
                        width: '100%',
                    },
                    otherContainerProps: {
                        gap: 0,
                    },
                    setOuterContainerRef: useOuterContainerRef ? setOptionsOuterContainerRef : undefined,
                }}
                loadingContainerStyles={{
                    marginTop: '18px',
                    paddingLeft: '18px',
                    paddingRight: '18px',
                }}
                infiniteRequestParams={[
                    {
                        ...searchByParams,
                        config: {
                            enabled: true,
                        },
                    },
                    extendedScreenerInfiniteRequestParamsConfig,
                ]}
                RowComponent={({ rowData }) => {
                    const orderedAuthors = sortPrimaryFirst(rowData?.research_report_author);
                    const orderedCompanies = sortPrimaryFirst(rowData?.research_report_security__trading);

                    return (
                        <ItemVariant3<ScreenerResearchData>
                            // use key to fix issue - the description has no height if the card on the previous render didn't have description
                            key={`ItemVariant3_${JSON.stringify(rowData)}`}
                            bottomPillItemsThreshold={3}
                            topText={rowData?.research_report?.research_type_name}
                            topSubText={moment(rowData?.research_report.publish_timestamp).format('MMMM D, YYYY')}
                            middleTitle={rowData?.research_report?.title}
                            middleSubText={rowData?.research_report?.teaser || ''}
                            middleContainerStyle={{
                                maxHeight: '135px',
                                overflow: 'hidden',
                                paddingBottom: '5px',
                            }}
                            bottomTextItems={orderedAuthors.map((author: any) => author?.platinum?.analyst?.pen_name)}
                            bottomPillItems={orderedCompanies?.map((company: any) => ({
                                content: company?.research_report_security?.security_trading?.ticker_symbol,
                                tooltipContent: company?.research_report_security?.company?.company_name,
                                onClick: () =>
                                    onPillClickHandler(
                                        `/company-profile/security/${company.research_report_security?.security_trading?.cfra_security_trading_id}`,
                                    ),
                            }))}
                            maxMiddleSubTextLength={125}
                            useLinkGetterParams={researchLinkGetterParams}
                            handleOuterSetRequestParamsProps={(
                                setRequestParamsProps: (props: GetRequestParamsProps) => void,
                            ) => {
                                setRequestParamsProps(getRequestParamsPropsVariant1(rowData?.id));
                            }}
                            topTextRightSlot={
                                (rowData?.frs_biggest_concerns_list?.is_biggest_concern && <BadgeBiggestConcerns />) ||
                                (rowData?.frs_hazard_list?.is_biggest_concern && <BadgeHazardList />)
                            }
                            containerStyles={{
                                margin: '18px',
                                flex: 1,
                                borderRadius: '10px',
                                border: '1px solid #E4E4E4',
                                padding: '18.75px 24px 24px',
                                ...gridViewItemContainerStyles,
                            }}
                        />
                    );
                }}
                noResultsFoundBoxSxProps={{ margin: '48px 0' }}
                {...getScreenerInfiniteOptionsProps<'research'>('research')}
                externalCallback={(data) => handleResultsCount(data)}
                scrollThresholdPx={scrollThresholdPx}
                outerContainerRef={useOuterContainerRef ? optionsOuterContainerRef : undefined}
            />
        );
    }, [
        onPillClickHandler,
        searchByParams,
        extendedScreenerInfiniteRequestParamsConfig,
        scrollThresholdPx,
        handleResultsCount,
        gridViewItemContainerStyles,
        optionsContainerSx,
        setOptionsOuterContainerRef,
        useOuterContainerRef,
    ]);

    return gridView;
}
