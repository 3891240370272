import { Layout } from '@cfra-nextgen-frontend/shared';
import { ScreenerResearchAnalystData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    TypographyStyle19,
    TypographyStyle29,
    TypographyStyle30,
    TypographyStyle32,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { Box, SxProps } from '@mui/material';
import { UseQueryResult } from 'react-query';
import { sendSingleRequest } from 'utils/api';
import { ProfilePicture } from './ProfileAvatar';

const containerSx: SxProps = {
    width: '100%',
    p: '26px',
    pb: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
};

const primaryDetailContainerSx: SxProps = {
    textAlign: 'left',
    p: '30px 30px 30px 0',
    borderBottom: '1.5px dotted #E4E4E4',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
};

const contactInfoContainerSx: SxProps = {
    textAlign: 'left',
    p: '18px 30px 18px 0',
    borderBottom: '1.5px dotted #E4E4E4',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
};

const bioContainerSx: SxProps = {
    textAlign: 'left',
    padding: '24px 30px 24px 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
};

const defaultTitle = "FRS Analyst"

export function AnalystProfileDetails({ analystId }: { analystId: number }) {
    const getAnalystProfileQuery = sendSingleRequest?.(
        {
            path: 'analyst/screener',
            securityType: 'analyst',
            view: 'profile',
            requestBody: {
                filters: {
                    values: {
                        'platinum.analyst.analyst_id': { values: [analystId] },
                    },
                },
            },
            config: {
                enabled: typeof analystId === 'number',
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'analyst/screener',
            queryKeyFirstElement: 'getAnalystProfileQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchAnalystData>;

    const profileData = getAnalystProfileQuery?.data?.results?.analyst?.[0] || {};

    return (
        <Box sx={{ width: '-webkit-fill-available', height: '100%', maxWidth: '996px' }}>
            {getAnalystProfileQuery?.isLoading ? (
                <Layout.Skeleton height={10} sx={{ margin: '100px 30px' }} />
            ) : (
                Object.keys(profileData).length > 0 && (
                    <Box sx={containerSx}>
                        <ProfilePicture
                            width='313.98px'
                            height='348px'
                            avatarDim='255px'
                            imageUrl={profileData?.analyst?.profile_picture_s3_url}
                            firstName={profileData?.analyst?.first_name}
                            lastName={profileData?.analyst?.last_name}
                            typographySx={{ lineHeight: '110px', fontSize: '100px' }}
                        />
                        <Box sx={primaryDetailContainerSx}>
                            <TypographyStyle29 lineHeight='34.7px'>
                                {profileData?.analyst?.first_name} {profileData?.analyst?.last_name}
                            </TypographyStyle29>
                            <TypographyStyle30 sx={{ lineHeight: '17.6px' }}>
                                {profileData?.analyst?.title || defaultTitle}
                            </TypographyStyle30>
                        </Box>

                        <Box sx={contactInfoContainerSx}>
                            <TypographyStyle30 sx={{ lineHeight: '20px', fontSize: '15px' }}>
                                Contact Information
                            </TypographyStyle30>
                            {profileData?.analyst?.phone && (
                                <TypographyStyle32 sx={{ lineHeight: '26px', p: '6px 0 2px 0' }}>
                                    {profileData.analyst.phone}
                                </TypographyStyle32>
                            )}

                            {profileData?.analyst?.email_address && (
                                <Box
                                    component='a'
                                    sx={{ width: 'min-content', textDecoration: 'none' }}
                                    href={`mailto:${profileData.analyst.email_address}`}>
                                    <TypographyStyle32 sx={{ color: '#007AB9', lineHeight: '26px' }}>
                                        {profileData.analyst.email_address}
                                    </TypographyStyle32>
                                </Box>
                            )}
                        </Box>

                        {profileData?.analyst?.bio && (
                            <Box sx={bioContainerSx}>
                                <TypographyStyle30 sx={{ lineHeight: '20px', fontSize: '15px', pb: '18px' }}>
                                    Bio
                                </TypographyStyle30>
                                <TypographyStyle19 sx={{ lineHeight: '26px', fontSize: '15px' }}>
                                    {profileData.analyst.bio}
                                </TypographyStyle19>
                            </Box>
                        )}
                    </Box>
                )
            )}
        </Box>
    );
}
