import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerDataWithGenericResultsKey } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { UserAlertData } from '@cfra-nextgen-frontend/shared/src/types/alert';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, Skeleton } from '@mui/material';
import { defaultListVariablesDelimiter } from 'analytics/analytics';
import { usePdfViewer } from 'hooks/usePdfViewer';
import { useContext, useLayoutEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getPrimaryObject, getSecondaryObjects } from 'utils/arrays';
import { PageNames } from 'utils/enums';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';

export function PdfViewer() {
    const [postponeRedirect, setPostponeRedirect] = useState(true);
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') ?? undefined;
    const alertId = searchParams.get('alert_id') ?? undefined;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    if (!id || !source || !alertId) {
        throw new Error('Missing required parameters: id, source and alert_id are required');
    }

    const getUserAlertQuery = sendSingleRequest<{ data: ScreenerDataWithGenericResultsKey<'data'> }>(
        { alertId, view: 'alert_analytics', noErrorOnNoKeyValuePairs: true },
        {
            path: 'alert',
            apiName: ApiNames.UserManagement,
            queryKeyFirstElement: 'getUserAlert',
            requestType: RequestTypes.GET,
        },
    );

    const reportAnalyticsRequestParams = researchLinkGetterParams[0].getRequestParams({
        ...getRequestParamsPropsVariant1(id),
    });

    const getReportDetailsQuery = sendSingleRequest<ScreenerDataWithGenericResultsKey<'research'>>(
        ...reportAnalyticsRequestParams,
    );

    // handle user analytics
    useLayoutEffect(() => {
        globalThis.analytics?.postPoneTracking?.();

        const userAlertData = getUserAlertQuery.data?.data?.results?.data?.['0'] as UserAlertData | undefined;
        const reportData = getReportDetailsQuery?.data?.results?.research?.['0'] as Record<string, any> | undefined;

        if (getUserAlertQuery.isLoading || !userAlertData || getReportDetailsQuery.isLoading || !reportData) {
            return;
        }

        const pageData: AnalyticsPageProps = {
            reportId: id,
            alertId,
            source,
            alertPrimarySourceId: userAlertData?.primary_source_id,
            alertType: joinWithDelimiter({
                leftValue: userAlertData.lookup_alert_type?.key,
                rightValue: String(userAlertData.lookup_alert_type?.lookup_id),
            }),
            alertEntityType: joinWithDelimiter({
                leftValue: userAlertData.lookup_alert_type?.lookup_entity_type?.key,
                rightValue: String(userAlertData.lookup_alert_type?.lookup_entity_type?.lookup_id),
            }),
            alertSubject: userAlertData.subject,
            alertDatetime: userAlertData.alert_time,
        };

        cfraData.pageData = { ...cfraData.pageData, ...pageData, captureActionProps: true };

        const researchReport = reportData?.research_report;
        const researchReportAuthor = reportData?.research_report_author;
        const primaryAuthor = getPrimaryObject(researchReportAuthor);
        const secondaryAuthors = getSecondaryObjects(researchReportAuthor);
        const getAuthorName = (authorData?: Record<string, any>) => authorData?.platinum?.analyst?.pen_name;

        const researchReportSecurityTrading = reportData?.research_report_security__trading;
        const primaryCompany = getPrimaryObject(researchReportSecurityTrading);
        const secondaryCompanies = getSecondaryObjects(researchReportSecurityTrading);
        const getCompanyName = (company?: Record<string, any>) =>
            company?.research_report_security?.company?.company_name;
        const getCompanyTicker = (company?: Record<string, any>) =>
            company?.research_report_security?.security_trading?.ticker_symbol;
        const getSecurityTradingId = (company?: Record<string, any>) =>
            company?.research_report_security?.security_trading?.cfra_security_trading_id;

        const gics = reportData?.research_report_security__company__gics;
        const primaryGics = getPrimaryObject(gics);
        const secondaryGics = getSecondaryObjects(gics);
        const getSectorData = (gics?: Record<string, any>) =>
            gics?.research_report_security?.company_sector?.lookup_gics_sector_lid;

        const actionData: AnalyticsActionProps = {
            action: PageNames.PdfViewer,
            reportType: joinWithDelimiter({
                leftValue: researchReport.research_type_name,
                rightValue: researchReport.research_type_id,
            }),
            reportName: researchReport.title,
            reportDomain: researchReport.research_category,
            reportAuthorPrimary: getAuthorName(primaryAuthor),
            reportAuthorSecondary: secondaryAuthors
                ?.map?.((reportAuthor: Record<string, any>) => getAuthorName(reportAuthor))
                .join(defaultListVariablesDelimiter),
            reportCompanyNamePrimary: joinWithDelimiter({
                leftValue: getCompanyName(primaryCompany),
                rightValue: getSecurityTradingId(primaryCompany),
            }),
            list1: secondaryCompanies?.map((company: Record<string, any>) =>
                joinWithDelimiter({
                    leftValue: getCompanyName(company),
                    rightValue: getSecurityTradingId(company),
                }),
            ),
            reportSectorPrimary: joinWithDelimiter({
                leftValue: getSectorData(primaryGics)?.value,
                rightValue: getSectorData(primaryGics)?.key,
            }),
            list2: secondaryGics?.map((gics) =>
                joinWithDelimiter({
                    leftValue: getSectorData(gics)?.value,
                    rightValue: getSectorData(gics)?.key,
                }),
            ),
            reportTickerPrimary: joinWithDelimiter({
                leftValue: getCompanyTicker(primaryCompany),
                rightValue: getSecurityTradingId(primaryCompany),
            }),
            list3: secondaryCompanies?.map((company: Record<string, any>) =>
                joinWithDelimiter({
                    leftValue: getCompanyTicker(company),
                    rightValue: getSecurityTradingId(company),
                }),
            ),
            isEntitled: true,
            reportId: id,
        };

        cfraData.actionData = { action: PageNames.PdfViewer, ...actionData };

        globalThis.analytics?.resumeTracking?.();
        
        setTimeout(() => setPostponeRedirect(false), 1000);
    }, [
        getUserAlertQuery,
        alertId,
        id,
        source,
        getReportDetailsQuery.data?.results?.research,
        getReportDetailsQuery.isLoading,
    ]);

    usePdfViewer({ id, source, alertId, postponeRedirect });

    return (
        <Box width='100%' height='100%'>
            <Skeleton sx={{ mt: '120px', mx: '20px', height: '30px' }} />
        </Box>
    );
}
