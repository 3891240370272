import { getUsageApiQueryKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import {
    EntityTypeLid,
    ProductLid,
    UsageRequestTypes,
    UsageTypeLid,
} from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { PageLid, RoutePaths } from './enums';

export function getUsageApiReqBody(type: UsageRequestTypes, params: any) {
    if (type === UsageRequestTypes.CompanyViewed) {
        if (typeof params.company_id !== 'string' && typeof params.security_id !== 'string') {
            throw new Error(`'company_id' or 'security_id' is required for usage type ${type}.`);
        }
        return {
            product_lid: ProductLid.ForensicAccountingResearch,
            usage_type_lid: UsageTypeLid.PageView,
            page_lid: PageLid.CompanyProfile,
            entity_type_lid: params.company_id ? EntityTypeLid.Company : EntityTypeLid.Equity,
            primary_entity_id: params.company_id || params.security_id,
        };
    } else if (type === UsageRequestTypes.ReportViewed) {
        if (typeof params.research_type_id !== 'number' || typeof params.id !== 'string') {
            throw new Error(`'research_type_id' and 'id' is required for usage type ${type}.`);
        }

        return {
            product_lid: ProductLid.ForensicAccountingResearch,
            usage_type_lid: UsageTypeLid.PageView,
            research_report_type_id: params.research_type_id,
            research_report_opensearch_id: params.id,
        };
    } else if (type === UsageRequestTypes.PdfViewerViewed) {
        const validations = {
            research_type_id: { type: 'number', value: params.research_type_id },
            id: { type: 'string', value: params.id },
            source_lid: { type: 'number', value: params.source_lid },
            alert_id: { type: 'number', value: params.alert_id },
        };

        for (const [field, { type, value }] of Object.entries(validations)) {
            if (value && typeof value !== type) {
                throw new Error(`'${field}' is required and must be of type ${type}`);
            }
        }

        return {
            product_lid: ProductLid.ForensicAccountingResearch,
            usage_type_lid: UsageTypeLid.PageView,
            research_report_type_id: params.research_type_id,
            research_report_opensearch_id: params.id,
            source_lid: params.source_lid,
            alert_id: params.alert_id,
        };
    }
}

export const companiesUsageQueryKey = getUsageApiQueryKey(UsageRequestTypes.SearchPerformed, 'companySearchUsageQuery');
export const searchTermsUsageQueryKey = getUsageApiQueryKey(UsageRequestTypes.SearchPerformed, 'searchTermsUsageQuery');

export const searchesUsageLoggerProps = {
    invalidateUsage: true,
    resetStateOnRequestDone: true,
    usageQueryKeys: [companiesUsageQueryKey, searchTermsUsageQueryKey],
    config: {
        enabled: false,
        cashTime: 1000,
        staleTime: 1000,
    },
};

export const defaultSearchUsageReqBody = {
    product_lid: ProductLid.ForensicAccountingResearch,
    usage_type_lid: UsageTypeLid.Search,
};

export function getPageLid() {
    let path = window.location.pathname;
    // remove slash in the end of the path
    path = path.endsWith('/') ? path.slice(0, -1) : path;

    if (path.startsWith(RoutePaths.CompanyProfile)) {
        return PageLid.CompanyProfile;
    }

    switch (path) {
        case '/':
            return PageLid.Home;
        case RoutePaths.ResearchHub:
        case RoutePaths.AccountingLensRiskMonitor:
        case RoutePaths.AccountingLensCompanyResearch:
        case RoutePaths.AccountingLensIndustryResearch:
        case RoutePaths.Scores:
        case RoutePaths.LegalEdge:
        case RoutePaths.BespokeServices:
        case RoutePaths.BespokeEdge:
        case RoutePaths.BespokeAdmin:
        case RoutePaths.Watchlists:
        case RoutePaths.ContactUs:
            const routePathKey = Object.keys(RoutePaths).find(
                (key) => RoutePaths[key as keyof typeof RoutePaths] === path,
            );

            if (!Object.keys(PageLid).includes(routePathKey as string)) {
                return;
            }

            return PageLid[routePathKey as keyof typeof PageLid];
    }
}
