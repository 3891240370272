import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { ReactComponent as TextIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/TextIcon.svg';
import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import { ItemVariant6 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant6';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { GetOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types';
import {
    AutoSuggestResearch,
    Hit,
    SearchResult,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch';
import { GetOptionsComponentProps } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { LinkGeneratorModes } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import {
    getSuggesterInfiniteOptionsProps,
    getSuggesterInfiniteRequestParamsConfig,
} from 'features/home/components/InfiniteOptions/suggester';
import moment from 'moment';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';
import { getLockModal } from 'components/LockComponent/getLockModalContent';

type Props = {
    size: number;
    getOptionsContainer: GetOptionsContainer;
    onOptionClick?: (inputValue: string) => void;
    viewMode?: 'standard' | 'tease'
};

export const determineGetResearchSuggesterOptions = ({ size, getOptionsContainer, onOptionClick, viewMode = 'standard' }: Props) => {
    const scrollThresholdPx = Math.round((112 * size) / 3); // take 33% of rendered size, assume each item height is 112px

    return function ({
        inputValue,
        resetOptionsRef,
        setThirdAreaRef,
        titleWithScrollableAreaProps,
        outerContainerRef,
    }: GetOptionsComponentProps) {
        return (
            <InfiniteOptions<SearchResult<AutoSuggestResearch>, Hit<AutoSuggestResearch>>
                outerContainerRef={outerContainerRef}
                OptionsContainer={getOptionsContainer(titleWithScrollableAreaProps)}
                ref={resetOptionsRef}
                infiniteRequestParams={[
                    {
                        q: inputValue,
                        path: 'research/suggester',
                        config: {
                            enabled: Boolean(inputValue),
                        },
                        size: size,
                        viewMode
                    },
                    {
                        queryKeyFirstElement: 'researchSuggesterQuery',
                        ...getSuggesterInfiniteRequestParamsConfig<AutoSuggestResearch>(size),
                    },
                ]}
                outerSetOptionsCondition={Boolean(inputValue)}
                RowComponent={({ rowData }) => {
                    const researchId = rowData._source['research_report._id'];
                    const publishDate = moment(rowData._source['research_report.publish_timestamp']).format(
                        'MMM D, YYYY',
                    );
                    const primaryAuthor = rowData._source['research_report_author']?.find(
                        (author) => author.is_primary === 1,
                    )?.['platinum.analyst.pen_name'];
                    const researchType = rowData._source['research_report.research_type_name'];
                    const title = rowData._source['research_report.title'];
                    const teaser = rowData._source['research_report.teaser'] || '';

                    const researchIcon = researchType === 'Legal Pipeline - Special Interest List' ? TextIcon : PdfIcon;

                    const hasModalFeature = researchType === 'Legal Pipeline - Special Interest List';
                    const linkGetterParams = {
                        ...researchLinkGetterParams[0],
                        mode: hasModalFeature ? LinkGeneratorModes.ExtractLink : LinkGeneratorModes.OpenInNewTab,
                    };
                    const hasLock = rowData._source['research_report.s3_pdf_url'] === '';

                    return (
                        <ItemVariant6<ScreenerResearchData>
                            firstRowItems={[publishDate, primaryAuthor, researchType]}
                            secondRowText={title}
                            thirdRowText={teaser}
                            icon={researchIcon}
                            useLinkGetterParams={[linkGetterParams]}
                            hasModalFeature={hasModalFeature}
                            setThirdAreaRef={setThirdAreaRef}
                            handleOuterSetRequestParamsProps={(setRequestParamsProps) => {
                                setRequestParamsProps(getRequestParamsPropsVariant1(researchId));
                            }}
                            externalOnClick={() => {
                                onOptionClick?.(inputValue);
                            }}
                            hasLock={hasLock}
                            ModalComponent={getLockModal({
                                content: title,
                                reportType: researchType,
                            })}
                        />
                    );
                }}
                rowsKeyPrefix={inputValue}
                noResultsFoundBoxSxProps={{ paddingLeft: '17px' }}
                loadingContainerStyles={{
                    paddingTop: '8px',
                    paddingBottom: '20px',
                }}
                {...getSuggesterInfiniteOptionsProps<AutoSuggestResearch>()}
                scrollThresholdPx={scrollThresholdPx}
            />
        );
    };
};
