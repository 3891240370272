import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { Box, CssBaseline, SxProps, Theme, ThemeProvider, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import React, { ComponentProps, Fragment, useCallback, useState } from 'react';
import { LockIconComponent } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/LockIconComponent';

export type ItemVariant9BaseProps = {
    firstRowItems: Array<string | undefined>;
    innerRef?: React.Ref<HTMLButtonElement>;
    secondRowText: string;
    thirdRowText?: string;
    itemTheme: Theme;
    hasModalFeature?: boolean;
    ModalComponent?: ComponentProps<typeof LockIconComponent>['ModalComponent'];
};

type ItemVariant9Props = ItemVariant9BaseProps & {
    onClick: () => void;
    extractedLink?: string;
    buttonSx?: SxProps;
    hasLock?: boolean;
};

const textContainerSx: SxProps = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
};

const summarySx: SxProps = {
    ...textContainerSx,
    pt: '4px',
    '@media (max-width: 500px)': {
        display: 'none',
    },
};

export function ItemVariant9({
    firstRowItems,
    innerRef,
    secondRowText,
    thirdRowText,
    itemTheme,
    hasModalFeature,
    onClick,
    extractedLink,
    buttonSx,
    hasLock = false,
    ModalComponent,
}: ItemVariant9Props) {
    const [modalOpen, setModalOpen] = useState(false);

    const onClickLocal = useCallback(() => {
        if (hasLock) {
            if (!modalOpen) setModalOpen(true);
        } else {
            onClick();
        }
    }, [onClick, hasLock, modalOpen]);

    return (
        <>
            <ThemeProvider theme={itemTheme}>
                <CssBaseline />
                <ButtonBase ref={innerRef} onClick={onClickLocal} sx={buttonSx}>
                    {/* First Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            paddingBottom: '3px',
                        }}>
                        {firstRowItems.map(
                            (item, index) =>
                                item && (
                                    <Fragment key={item}>
                                        <Typography variant={index === 0 ? 'researchType' : 'caption'} key={item}>
                                            {item}
                                        </Typography>
                                        {index < firstRowItems.length - 1 && <DividerStyle1 />}
                                    </Fragment>
                                ),
                        )}
                    </Box>
                    {/* Second Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                        }}>
                        {hasLock && ModalComponent && (
                            <LockIconComponent
                                modalOpen={modalOpen}
                                setModalOpen={setModalOpen}
                                ModalComponent={ModalComponent}
                            />
                        )}
                        <Typography variant='headlineTitle'>{secondRowText}</Typography>
                    </Box>
                    {!hasLock && thirdRowText && (
                        <Box sx={summarySx}>
                            <Typography variant='summary'>{thirdRowText}</Typography>
                        </Box>
                    )}
                </ButtonBase>
            </ThemeProvider>
        </>
    );
}