export function sortPrimaryFirst(array?: Array<{ is_primary: boolean }>) {
    return array?.sort((a: any, b: any) => b?.is_primary - a?.is_primary) || [];
}

export function getPrimaryObject(data?: Array<Record<string, any>>): Record<string, any> | undefined {
    return data?.find((data) => data.is_primary === 1);
}

export function getSecondaryObjects(data?: Array<Record<string, any>>): Array<Record<string, any>> | undefined {
    return data?.filter((reportAuthor: Record<string, any>) => reportAuthor.is_primary === 0);
}
