import { ENABLE_MSW, ENVIRONMENT_NAME, JIRA_ISSUE_COLLECTOR_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { Environments } from '@cfra-nextgen-frontend/shared/src/utils';
import { loadScript } from '@cfra-nextgen-frontend/shared/src/utils/links';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Analytics } from 'analytics/analytics';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

loadScript(document, JIRA_ISSUE_COLLECTOR_URL);

// Start the mocking conditionally.
if (
    [Environments.Local, Environments.Development, Environments.UAT].includes(ENVIRONMENT_NAME as Environments) &&
    ENABLE_MSW
) {
    // The way this is set up should prevent this from being in production build
    const { worker } = require('./test/server/mocks/browser'); // eslint-disable-line global-require
    worker.start();
}

globalThis.analytics = new Analytics();

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// TODO: This comes with Create React App. Will research best way to handle analytics in a future
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
