import { Button } from '@cfra-nextgen-frontend/shared';
import { Grid, useMediaQuery } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { useContext, useState, useEffect } from 'react';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ApiNames, fontFamilies, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { useSnackbar } from 'notistack';
import { useUserData } from 'features/contactUs/components/useUserData';

const styles = {
    label: {
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '16px',
        color: '#002B5A',
        display: 'flex',
        fontWeight: '500',
        span: {
            fontFamily: fontFamilies.GraphikRegular,
            fontSize: '13px',
            fontWeight: '400',
        },
        lineHeight: '2em',
    },
    inputField: {
        width: '100%',
        backgroundColor: '#F9FAFC',
        borderRadius: '8px',
        color: '#7A7777',
        padding: 0,
        height: '47px',
        fontFamily: fontFamilies.GraphikMedium,
        fontWeight: '500',
    },
    span: {
        fontFamily: fontFamilies.GraphikRegular,
        color: 'red',
    },
};
interface FormData {
    name?: string;
    company: string;
    phone: string;
    email: string;
    message: string;
}

export type ContactFormProps = {
    onClick?: () => void;
    handleClose?: () => void;
    onClickPath?: string;
    thankyouMessage?: string;
    subjectPrefix?: string;
    hasSubjectField?: boolean;
    hasCompanyField?: boolean;
    hasNameFiels?: boolean;
    hasTitleName?: boolean;
    buttonCenterStyle?: boolean;
    buttonIsSend?: boolean;
    backgroundStyle?: boolean;
    onSubmitForNotEntitled?: boolean;
    onSubmitForContacts?: boolean;
    onSuccess?: () => void;
    titleCardName?: string;
    titlePageName?: string;
    subjectTitle?: string;
    lineInputWidth?: boolean;
    marginStyle?: boolean;
    paddingSpacingProp?: boolean;
    messageRequiredStyle?: boolean;
    borderStyle?: boolean;
    onSubmitNotEntitledNewFormData?: Record<string, string>;
    buttomPaddingStyle?: boolean;
    messageRequiredSizeStyle?: boolean;
};

export function ContactForm({
    thankyouMessage = 'Thank you for reaching out to us. Your query has been received, and our team will respond to you as soon as possible',
    subjectPrefix = 'Support Request',
    hasSubjectField,
    hasCompanyField = true,
    hasNameFiels = true,
    hasTitleName = true,
    buttonCenterStyle,
    buttonIsSend,
    backgroundStyle,
    onSubmitForNotEntitled,
    onSubmitForContacts = true,
    onSuccess,
    titleCardName,
    titlePageName,
    subjectTitle,
    lineInputWidth,
    marginStyle,
    paddingSpacingProp,
    messageRequiredStyle,
    borderStyle,
    onSubmitNotEntitledNewFormData,
    buttomPaddingStyle,
    messageRequiredSizeStyle,
}: ContactFormProps) {
    const userData = useUserData();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState<FormData | null>(null);
    const [notification, setNotification] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (userData) {
            setValue('name', `${userData.first_name} ${userData.last_name}`);
            setValue('company', userData.company || '');
            setValue('phone', userData.phone || '');
            setValue('email', userData.email || '');
        }
    }, [userData, setValue]);

    const onSubmit = (data: any) => {
        if (!onSubmitForContacts) return;
        clearErrors('form');

        const fieldsToValidate = [
            { name: 'name', message: "Your Name cannot be 'Not Available'." },
            { name: 'company', message: "Company cannot be 'Not Available'." },
            { name: 'phone', message: "Phone Number cannot be 'Not Available'." },
            { name: 'email', message: "Your email address cannot be 'Not Available'." },
            { name: 'message', message: "Your message cannot be 'Not Available'." },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ name, message }) => {
            if (!data[name] || data[name] === 'Not Available') {
                setError(name, {
                    type: 'manual',
                    message: message,
                });
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        const newFormData = {
            name: hasNameFiels ? `${userData.first_name} ${userData.last_name}` : undefined,
            phone: data.phone,
            email: userData.email,
            message: data.message,
            company: hasCompanyField ? data.company : undefined,
            subject: hasSubjectField
                ? `${subjectPrefix} ${data.subject}`
                : `${subjectPrefix} ${userData.first_name} ${userData.last_name}`,
        };

        setFormData(newFormData);
        setIsSubmitting(true);
    };

    const onSubmitNotEntitled = (data: any) => {
        if (!onSubmitForNotEntitled) return;
        clearErrors('form');

        const fieldsToValidate = [
            { name: 'name', message: "Your Name cannot be 'Not Available'." },
            { name: 'company', message: "Company cannot be 'Not Available'." },
            { name: 'phone', message: "Phone Number cannot be 'Not Available'." },
            { name: 'email', message: "Your email address cannot be 'Not Available'." },
            { name: 'message', message: "Your message cannot be 'Not Available'." },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ name, message }) => {
            if (!data[name] || data[name] === 'Not Available') {
                setError(name, {
                    type: 'manual',
                    message: message,
                });
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        const newFormData = {
            name: hasNameFiels ? `${userData.first_name} ${userData.last_name}` : undefined,
            phone: data.phone,
            email: userData.email,
            message: data.message,
            company: hasCompanyField ? data.company : undefined,
            pageName: titlePageName,
            cardName: titleCardName,
            subject: subjectTitle,
            isMoreInsights: 1,
            ...onSubmitNotEntitledNewFormData,
        };

        setFormData(newFormData);
        setIsSubmitting(true);
    };

    useEffect(() => {
        if (notification) {
            enqueueSnackbar(notification, { variant: 'success' });
            setNotification('');
            reset();
            setIsSubmitting(false);
            if (userData) {
                setValue('name', `${userData.first_name} ${userData.last_name}`);
                setValue('company', userData.company || '');
                setValue('phone', userData.phone || '');
                setValue('email', userData.email || '');
            }
        }
    }, [notification, enqueueSnackbar, reset, setValue, userData]);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const response = sendSingleRequest(
        {
            requestBody: formData,
            config: {
                enabled: isSubmitting,
            },
            noErrorOnNoKeyValuePairs: true,
        },
        {
            requestType: RequestTypes.POST,
            path: 'send-email',
            queryKeyFirstElement: 'sendEmail',
            apiName: ApiNames.UserManagement,
        },
    );

    useEffect(() => {
        if (!response?.isLoading && response?.data) {
            if ((response?.data as any)?.data.message_id) {
                setNotification(thankyouMessage);
                setTimeout(() => {
                    onSuccess?.();
                }, 200);
            }
        }
    }, [response?.isLoading, response?.data, thankyouMessage, onSuccess]);

    const isPhone = useMediaQuery('(max-width:768.5px)');

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                if (onSubmitForContacts) {
                    handleSubmit(onSubmit)(e);
                } else if (onSubmitForNotEntitled) {
                    handleSubmit(onSubmitNotEntitled)(e);
                }
            }}>
            <Grid container direction='column' spacing={paddingSpacingProp ? 1 : 2}>
                <Grid item>
                    {hasTitleName ? (
                        <FormLabel htmlFor='name' sx={{ ...styles.label }}>
                            Name
                        </FormLabel>
                    ) : (
                        ''
                    )}
                    <TextField
                        fullWidth
                        defaultValue={
                            userData.first_name && userData.last_name
                                ? `${userData.first_name} ${userData.last_name}`
                                : `${userData.first_name}`
                        }
                        {...register('name', { required: true })}
                        placeholder='Your Name'
                        variant='outlined'
                        InputProps={{
                            style: { ...styles.inputField },
                        }}
                        sx={{ ...styles.inputField }}
                    />
                    {errors.name && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>
                {hasCompanyField ? (
                    <Grid item>
                        <FormLabel htmlFor='company' sx={{ ...styles.label }}>
                            Company
                        </FormLabel>
                        <TextField
                            fullWidth
                            {...register('company', { required: true })}
                            placeholder='Product Type'
                            variant='outlined'
                            InputProps={{
                                style: { ...styles.inputField },
                            }}
                            sx={{ ...styles.inputField }}
                        />
                        {errors.company && (
                            <Grid item>
                                <span style={{ ...styles.span }}>This field is required</span>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    ''
                )}

                <Grid item>
                    {hasTitleName ? (
                        <FormLabel htmlFor='phone' sx={{ ...styles.label }}>
                            Phone Number
                        </FormLabel>
                    ) : (
                        ''
                    )}
                    <TextField
                        fullWidth
                        {...register('phone', { required: true })}
                        placeholder='Phone Number'
                        variant='outlined'
                        InputProps={{
                            style: { ...styles.inputField },
                        }}
                        sx={{ ...styles.inputField }}
                    />
                    {errors.phone && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>

                <Grid item>
                    {hasTitleName ? (
                        <FormLabel htmlFor='email' sx={{ ...styles.label }}>
                            Email &nbsp;<span>(so we can reply to you)</span>
                        </FormLabel>
                    ) : (
                        ''
                    )}
                    <TextField
                        fullWidth
                        {...register('email', { required: true })}
                        placeholder='Your email Address'
                        variant='outlined'
                        InputProps={{
                            style: { ...styles.inputField },
                        }}
                        sx={{ ...styles.inputField }}
                    />
                    {errors.email && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>
                {hasSubjectField ? (
                    <Grid item>
                        <FormLabel htmlFor='subject' sx={{ ...styles.label }}>
                            Subject
                        </FormLabel>
                        <TextField
                            fullWidth
                            {...register('subject', {
                                required: true,
                                maxLength: 40,
                            })}
                            placeholder='Subject of your request'
                            variant='outlined'
                            InputProps={{
                                style: { ...styles.inputField },
                            }}
                            sx={styles.inputField}
                        />
                        {errors.subject && (
                            <Grid item>
                                {errors.subject.type === 'required' && (
                                    <span style={{ ...styles.span }}>This field is required</span>
                                )}
                                {errors.subject.type === 'maxLength' && (
                                    <span style={{ ...styles.span }}>Character limit is 40, including spaces</span>
                                )}
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    ''
                )}
                <Grid item>
                    {hasTitleName ? (
                        <FormLabel htmlFor='message' sx={{ ...styles.label }}>
                            Your Message
                        </FormLabel>
                    ) : (
                        ''
                    )}
                    <TextField
                        fullWidth
                        multiline
                        rows={lineInputWidth ? 1 : 4}
                        {...register('message', { required: true })}
                        placeholder='Submit questions or comments'
                        variant='outlined'
                        InputProps={{
                            style: { ...styles.inputField, padding: '16.5px 14px', height: 'unset' },
                        }}
                        sx={{ ...styles.inputField }}
                    />
                    {errors.message && (
                        <Grid
                            item
                            sx={{
                                paddingTop: messageRequiredStyle
                                    ? messageRequiredSizeStyle
                                        ? '5px'
                                        : '10px'
                                    : 'unset',
                                marginBottom: messageRequiredStyle
                                    ? messageRequiredSizeStyle
                                        ? '-20px'
                                        : '-15px'
                                    : 'unset',
                            }}>
                            <span style={{ ...styles.span, fontSize: messageRequiredSizeStyle ? '10px' : 'unset' }}>
                                This field is required
                            </span>
                        </Grid>
                    )}
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: buttonCenterStyle ? 'center' : 'flex-end' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={isSubmitting}
                        style={{
                            display: 'flex',
                            backgroundColor: backgroundStyle ? '#0A6DD2' : '#002B5A',
                            color: '#FFFFFF',
                            textTransform: 'initial',
                            borderRadius: '8px',
                            padding: buttomPaddingStyle ? '5px 20px' : '13px 33px',
                            width: isPhone ? '100%' : 'unset',
                            marginTop: marginStyle ? '8px' : '80px',
                            borderBottom: borderStyle ? 'unset' : '1px solid #E4E4E4',
                            boxShadow: 'none',
                            alignItems: 'center',
                        }}>
                        {buttonIsSend
                            ? isSubmitting
                                ? 'Sending...'
                                : 'Send'
                            : isSubmitting
                            ? 'Submitting...'
                            : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
