import { StyledMenuItem } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRARoundedSelect';
import { CFRASelectVariant5 } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRASelectVariant5';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { LookupItems, RegionId, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { SelectChangeEvent } from '@mui/material';
import { getLockModal } from 'components/LockComponent/getLockModalContent';
import { difference } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type RegionSelectProps = {
    onChange: (regions: string[], selectedOption: RegionSelectOptions) => void;
};

export enum RegionSelectOptions {
    AllRegions = 'All Regions',
    NorthAmerica = 'North America',
    Europe = 'Europe',
}

export function RegionSelect({ onChange }: RegionSelectProps) {
    const { userEntitlements } = useUserEntitlements();
    const [modalOpen, setModalOpen] = useState(false);

    const LockModal = getLockModal({
        content: 'Biggest Concern Access',
    });

    const regionMapping: Record<RegionSelectOptions, number[]> = useMemo(
        () => ({
            [RegionSelectOptions.AllRegions]: [RegionId.FRSAmericas, RegionId.FRSEurope],
            [RegionSelectOptions.NorthAmerica]: [RegionId.FRSAmericas],
            [RegionSelectOptions.Europe]: [RegionId.FRSEurope],
        }),
        [],
    );

    const entitlementMappings = useMemo(() => {
        let regionEntitlements: number[] = [];

        userEntitlements?.packages.forEach((packg) => {
            const companyReportEntitled = packg.entitlements?.some(
                (ent) =>
                    ent.item_lid === LookupItems.ResearchTypeId &&
                    Number(ent.value) === ResearchTypeId.FrsCompanyReport,
            );

            if (companyReportEntitled)
                regionEntitlements = regionEntitlements.concat(
                    packg.entitlements
                        ?.filter((ent) => ent.item_lid === LookupItems.RegionLid)
                        .map((ent) => Number(ent.value)) || [],
                );
        });

        return {
            [RegionSelectOptions.AllRegions]:
                difference(regionMapping[RegionSelectOptions.AllRegions], regionEntitlements).length === 0,
            [RegionSelectOptions.NorthAmerica]:
                difference(regionMapping[RegionSelectOptions.NorthAmerica], regionEntitlements).length === 0,
            [RegionSelectOptions.Europe]:
                difference(regionMapping[RegionSelectOptions.Europe], regionEntitlements).length === 0,
        };
    }, [regionMapping, userEntitlements]);

    const options = useMemo(() => {
        return [
            {
                value: RegionSelectOptions.AllRegions,
                disabled: !entitlementMappings[RegionSelectOptions.AllRegions],
            },
            {
                value: RegionSelectOptions.NorthAmerica,
                disabled: !entitlementMappings[RegionSelectOptions.NorthAmerica],
            },
            {
                value: RegionSelectOptions.Europe,
                disabled: !entitlementMappings[RegionSelectOptions.Europe],
            },
        ];
    }, [entitlementMappings]);

    const getDefaultSelectionIndex = (entitlementMappings: Record<string, boolean>) => {
        return entitlementMappings[RegionSelectOptions.AllRegions]
            ? 0
            : entitlementMappings[RegionSelectOptions.NorthAmerica]
            ? 1
            : entitlementMappings[RegionSelectOptions.Europe]
            ? 2
            : 0;
    };

    const [currentSelection, setCurrentSelection] = useState<RegionSelectOptions>();

    const handleChange = useCallback(
        (value: RegionSelectOptions) => {
            const index = options.findIndex((option) => option.value === value);
            const selectedRegion = options[index];

            if (options[index].disabled) {
                setModalOpen(true);
                return;
            }

            setCurrentSelection(value);
            onChange(
                regionMapping[selectedRegion.value].map((v) => v.toString()),
                value,
            );
        },
        [onChange, options, regionMapping],
    );

    useEffect(() => {
        if (currentSelection === undefined && userEntitlements !== undefined) {
            handleChange(Object.values(RegionSelectOptions)[getDefaultSelectionIndex(entitlementMappings)]);
        }
    }, [handleChange, entitlementMappings, currentSelection, userEntitlements]);

    return (
        <>
            <CFRASelectVariant5
                key='RegionSelect'
                selectItems={options.map(({ value, disabled }) => {
                    return {
                        menuItem: (
                            <StyledMenuItem
                                value={value}
                                key={value}
                                sx={
                                    disabled
                                        ? {
                                              backgroundColor: '#D5D8DE',
                                              '&:hover': {
                                                  backgroundColor: '#D5D8DE',
                                              },
                                          }
                                        : {}
                                }>
                                <></>
                                {value}
                            </StyledMenuItem>
                        ),
                    };
                })}
                handleSelectionChange={(e: SelectChangeEvent<unknown>) => {
                    handleChange(e.target.value as RegionSelectOptions);
                }}
                currentSelection={currentSelection}
                width={138}
            />
            <LockModal
                modalOpen={modalOpen}
                handleClose={() => setModalOpen(false)}
                handleCloseModal={() => setModalOpen(false)}
            />
        </>
    );
}
