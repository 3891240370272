import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { createTheme } from '@mui/material';
import { forwardRef } from 'react';
import { Toggle } from './Toggle';

export type ToggleVariant1Props = {
    defaultSelectionIndex?: number;
    options: Array<{ value: string; disabled?: boolean }>;
    onChange: (index: number) => void;
    onDisabledClick?: (index: number) => void;
};

const boxShadowValue =
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)';

const ToggleThemeVariant1 = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'concerns' },
                    style: {
                        backgroundColor: '#F66C61',
                        fontFamily: fontFamilies.GraphikSemibold,
                        fontSize: '10px',
                        lineHeight: '28px',
                        color: '#FFF',
                        fontWeight: 600,
                        height: '24px',
                        width: 'auto',
                        '&:hover': {
                            backgroundColor: '#F66C61',
                        },
                    },
                },
            ],
        },
    },
});

export type ToggleVariant1Ref = {
    resetToggle: () => void;
    externalSetSelectedOptionIndex: (index: number) => void;
};

export const ToggleVariant1 = forwardRef<ToggleVariant1Ref, ToggleVariant1Props>(
    ({ defaultSelectionIndex = 0, options, onChange, onDisabledClick }, ref) => {
        return (
            <Toggle
                ref={ref}
                defaultSelectionIndex={defaultSelectionIndex}
                options={options}
                onChange={onChange}
                onDisabledClick={onDisabledClick}
                theme={ToggleThemeVariant1}
                sx={{ background: '#E4E8F4', borderRadius: '30px' }}
                getOptionSx={(option, index, selectedOption) => {
                    return (theme) => ({
                        padding: '10px 20px',
                        borderRadius: '20px',
                        background: selectedOption === index && !option.disabled ? '#ffffff' : '#E4E8F4',
                        color: option.disabled ? '#00000042' : selectedOption === index ? '#007AB9' : '#666666',
                        fontFamily: fontFamilies.GraphikSemibold,
                        '&:hover': {
                            background: !option.disabled ? '#ffffff' : 'none',
                            border: 'none',
                            boxShadow: option.disabled ? 'none' : boxShadowValue,
                        },
                        margin: 0,
                        border: 'none',
                        textTransform: 'none',
                        [theme.breakpoints.down(400)]: {
                            padding: '5px 15px',
                        },
                        boxShadow: option.disabled ? 'none' : selectedOption === index ? boxShadowValue : 'initial',
                        cursor: option.disabled ? 'default' : 'pointer',
                    });
                }}
            />
        );
    },
);
