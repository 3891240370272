import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { Route, Routes } from 'react-router-dom';
import { WatchlistHome } from '../components/WatchlistHome';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';
import { Page } from 'analytics/Page';
import { PageNames } from 'utils/enums';

export function WatchlistRoutes() {
    return (
        <Routes>
            <Route
                element={
                    <PageWithComponentInHeader
                        tabContainerSx={{
                            paddingTop: horizontalPaddingInSu,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: maxPageWidthInPx,
                            boxSizing: 'content-box',
                            backgroundColor: 'unset',
                            width: '100%',
                        }}
                        pageContainerSx={{
                            maxWidth: '100%',
                            paddingLeft: horizontalPaddingInSu,
                            paddingRight: horizontalPaddingInSu,
                            display: 'flex',
                            justifyContent: 'center',
                            minHeight: '0px',
                        }}
                    />
                }>
                <Route
                    path='*'
                    element={
                        <Page name={PageNames.Watchlists}>
                            <WatchlistHome />
                        </Page>
                    }
                />
            </Route>
        </Routes>
    );
}
