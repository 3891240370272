import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Grid, useMediaQuery } from '@mui/material';
import { ResearchComponent } from 'components/ResearchComponent';
import { SIZE_WHEN_SIDEBAR } from 'components/ResearchComponent/components/constants';
import { GetFiltersJsxConfig } from 'components/ResearchComponent/components/filters/shared';
import { MoreInsights } from 'components/ResearchComponent/components/MoreInsights';
import { PopularSearches } from 'components/ResearchComponent/components/PopularSearches';
import { ResearchComponentRef } from 'components/ResearchComponent/components/ResearchComponent';
import { commonCustomBreakpointsTheme } from 'components/themes/customBreakpointsTheme';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sectorImageMap } from 'utils/mappings';
import { Locations, UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';

const filtersConfig: GetFiltersJsxConfig = {
    pillsRowComponentProps: {
        label: 'Sector',
        filterMetadataKey: 'insights.research_report.composite_gics_sector_code',
        imageMap: sectorImageMap,
    },
    virtualizeAutocompleteComponentsProps: [
        {
            defaultInputLabel: 'Sub-Industry',
            filterMetadataKey: 'insights.research_report.composite_gics_sub_industry_code',
        },
        {
            defaultInputLabel: 'Document Type',
            filterMetadataKey: 'insights.research_report.research_type_name',
        },
        {
            defaultInputLabel: 'Region',
            filterMetadataKey: 'insights.research_report.composite_region_country_lid',
        },
        {
            defaultInputLabel: 'Author',
            filterMetadataKey: 'insights.research_report_author.analyst_id',
        },
    ],
    dateRangePickerComponentProps: {
        label: 'Date From/To',
        filterMetadataKey: 'insights.research_report.publish_timestamp',
    },
    watchlistProps: {
        label: 'Watchlist',
    },
};

type sideBarComponentRef = {
    updateSideBarSearchByParams: (searchByParams: SearchByParams) => void;
};

export function ResearchHubHome() {
    const researchComponentRef = useRef<ResearchComponentRef>(null);
    const sideBarComponentRef = useRef<sideBarComponentRef>(null);

    // check for isBelowLg outside of ResearchComponent just in this case, as an exception to control the sideBarComponent layout
    // all else logic related to the isBelowLg should be inside the ResearchComponent
    const isBelowLg = useMediaQuery(commonCustomBreakpointsTheme.breakpoints.down('lg'));
    const { state } = useLocation();
    const [config] = useState(getFiltersConfig(state));

    // reset state once consumed
    useEffect(() => {
        window.history.replaceState({}, '');
    }, [config]);

    return (
        <ResearchComponent
            ref={researchComponentRef}
            resultsCardTitleOnMobile='Research Hub'
            sideBarComponentRef={sideBarComponentRef}
            filtersFormProps={{
                filtersConfig: config,
            }}
            sideBarComponent={
                <Grid container gap={isBelowLg ? 1.75 : 4.5}>
                    <Grid item xs={12}>
                        <PopularSearches
                            onSearchTermPillClick={(searchTerm) => {
                                if (!researchComponentRef.current) {
                                    return;
                                }

                                researchComponentRef.current.onSearchTermClick(searchTerm);
                            }}
                            onCompanyPillClick={(primaryEntityId, ticker, exchangeCode) => {
                                if (!researchComponentRef.current) {
                                    return;
                                }

                                researchComponentRef.current.onCompanyClick(primaryEntityId, ticker, exchangeCode);
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12}>
                        <MoreInsights ref={sideBarComponentRef} />
                    </Grid>
                </Grid>
            }
            queriesKeyFirstElementPostfix='research_hub'
            screenerSearchByParams={{
                view: 'research_hub',
                size: SIZE_WHEN_SIDEBAR,
            }}
            gridViewItemContainerStyles={{
                // show 1 column on the range [0px;669px] screen width
                // show 2 columns on the range [670px;1199px] screen width
                // show 3 column automatically

                // width when 3 columns
                width: '294.5px',
                minWidth: '294.5px',

                // no min width for the smallest screens
                '@media (max-width: 360.5px)': {
                    width: 'auto',
                    minWidth: 'auto',
                },

                // keep 2 columns up to 1199 px including
                '@media (min-width: 670px) and (max-width: 747px)': {
                    minWidth: '256.5px',
                },
            }}
            preferencesConfiguration={{
                useUserPreferencesProps,
                selectorConfiguration: {
                    [`${Locations.SearchResults}_${Views.TableView}` as const]: {
                        selector: UserPreferencesSelectors[UserPreferences.ResearchHubSearchResultsTableView],
                    },
                    [Locations.SearchResults]: {
                        selector: UserPreferencesSelectors[UserPreferences.ResearchHubSearchResults],
                    },
                },
            }}
        />
    );
}

function getFiltersConfig(state: any) {
    const { filtersDefaultValues } = state ?? {};
    if (!filtersDefaultValues) return filtersConfig;

    return Object.keys(filtersConfig).reduce(
        (updatedConfig: any, key) => {
            const value = filtersConfig[key as keyof typeof filtersConfig];

            updatedConfig[key] = Array.isArray(value)
                ? value.map((item) => {
                      const defaultValue = filtersDefaultValues[item.filterMetadataKey];
                      return defaultValue !== undefined ? { ...item, defaultValues: defaultValue } : item;
                  })
                : value;

            return updatedConfig;
        },
        { ...filtersConfig },
    );
}
