import { Navigate, Route, Routes } from 'react-router-dom';
import { PdfViewer } from '../components/PdfViewer';
import { Page } from 'analytics/Page';
import { PageNames } from 'utils/enums';

export function PdfViewerRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                path='/:id'
                element={
                    <Page name={PageNames.PdfViewer}>
                        <PdfViewer />
                    </Page>
                }
            />
        </Routes>
    );
}
