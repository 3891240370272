import { cloneDeep, merge } from 'lodash';
import { CFRASelectVariant3, CFRASelectVariant3Props, CFRASelectVariant3ThemeOptions } from './CFRASelectVariant3';
import { fontFamilies } from '../../utils/fonts';

const CFRASelectVariant5ThemeOptions = {
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    minHeight: 'unset',
                    lineHeight: '28px',
                    fontSize: '13.5px !important',
                    fontFamily: fontFamilies.GraphikRegular,
                    letterSpacing: 'normal !important',
                    color: '#3C3C3C !important',
                    paddingTop: '8px !important',
                    paddingBottom: '8px !important',
                    paddingLeft: '12px !important',
                    paddingRight: '12px !important',
                    width: '198px !important',
                    height: '28px !important',
                    display: 'flex',
                    alignItems: 'center',
                },
                icon: {
                    right: '14px',
                    top: '17px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: '198px !important',
                    minWidth: 'unset !important',
                    height: '44px !important',
                    padding: '0px !important',
                    letterSpacing: 'normal !important',
                    border: '1px solid #E4E4E4 !important',
                    borderRadius: '5px !important',
                    '& .option-component': {
                        width: 'calc(100% - 20px)',
                    },
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '& .option-component': {
                        width: '100%',
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: '5px !important',
                },
            },
        },
    },
};

type CFRASelectVariant5Props = {
    width?: number;
} & CFRASelectVariant3Props;

export function CFRASelectVariant5({ width, ...props }: CFRASelectVariant5Props) {
    return (
        <CFRASelectVariant3
            {...props}
            externalThemeOptions={merge(cloneDeep(CFRASelectVariant3ThemeOptions), CFRASelectVariant5ThemeOptions, {
                components: {
                    MuiSelect: {
                        styleOverrides: {
                            select: {
                                ...(width && { width: `${width}px !important` }),
                            },
                        },
                    },
                    MuiInputBase: {
                        styleOverrides: {
                            root: {
                                ...(width && { width: `${width}px !important` }),
                            },
                        },
                    },
                },
            })}
        />
    );
}
