import { Box, SxProps } from '@mui/material';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';
import { TypographyStyle20 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';

export function BcLabel({ containerSx }: { containerSx?: SxProps }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ...containerSx,
            }}>
            <BadgeHazardList />
            <Box sx={{marginLeft: '5px', marginRight: '10px' }}><TypographyStyle20>On Hazard List</TypographyStyle20></Box>
            <BadgeBiggestConcerns />
            <Box sx={{ marginLeft: '5px' }}><TypographyStyle20>On Biggest Concerns List</TypographyStyle20></Box>
        </Box>
    );
}
