import { Layout } from '@cfra-nextgen-frontend/shared';
import { ScreenerDataWithGenericResultsKey } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { fontWeights, formatValue, ValueTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, createTheme, Stack, SxProps, Theme, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { CompanyParams } from '../types/company';
import { TypographyStyle11, TypographyStyle15, TypographyStyle2, TypographyStyle7, TypographyStyle8 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { CFRAStockChart } from '@cfra-nextgen-frontend/shared/src/components/Chart/CFRAStockChart';
import HighchartsReact from 'highcharts-react-official';
import { CurrencySymbols } from '@cfra-nextgen-frontend/shared/src/utils/currency';
import moment from 'moment';

const containerSx: SxProps = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
    marginTop: '30px'
};

const headerSx: SxProps = {
    p: '24px 28px 10px 28px',
    alignItems: 'center',
};

const loadingCntrSx: SxProps = { m: '48px 0', width: '100%', textAlign: 'center' };

const financialDataPoints = [
    {
        label: 'Close Price',
        getValue: (latestData: any) => {
            return formatValue({
                value: latestData.security_price?.['price_close_unadjusted'], formattingType: ValueTypes.Currency,
                additionalConfig: { listing_currency: CurrencySymbols[latestData.security_currency_symbol] }
            });
        },
    },
    {
        label: 'Market Cap',
        getValue: (latestData: any) => {
            return formatValue({
                value: latestData.company_stats_latest?.['market_cap'], formattingType: ValueTypes.Currency,
                additionalConfig: { listing_currency: CurrencySymbols[latestData.company_currency_symbol] }
            }) + (latestData.company_stats_latest?.['market_cap'] ? ' M' : '');
        },
    },
    {
        label: '52 Week Range',
        getValue: (latestData: any) => {
            
            const low_52 = formatValue({
                value: latestData.security_price?.['_52_low'], formattingType: ValueTypes.Currency,
                additionalConfig: { listing_currency: CurrencySymbols[latestData.security_currency_symbol] }
            })

            const high_52 = formatValue({
                value: latestData.security_price?.['_52_high'], formattingType: ValueTypes.Currency,
                additionalConfig: { listing_currency: CurrencySymbols[latestData.security_currency_symbol] }
            })

            return `${low_52} ${latestData.security_price?.['_52_high'] ? ('- ' + high_52) : ''}`
        },
    },
    {
        label: 'Shares Out.',
        getValue: (latestData: any) => {
            return formatValue({ value: latestData.company_stats_latest?.['shares_outstanding'], formattingType: ValueTypes.MillionsScale });
        },
    },
    {
        label: 'Volume',
        getValue: (latestData: any) => {
            return formatValue({ value: latestData.security_price?.['volume'], formattingType: ValueTypes.MillionsScale });
        },
    },
    {
        label: 'Dividend Yield',
        getValue: (latestData: any) => {
            return formatValue({ value: latestData.security_price_calcs?.['div_yield'], formattingType: ValueTypes.Numeral });
        },
    },
    {
        label: 'Volume MDA20',
        getValue: (latestData: any) => {
            return formatValue({ value: latestData.security_price_calcs?.['volume_20_dma'], formattingType: ValueTypes.MillionsScale });
        },
    },
    {
        label: 'Return 12M',
        getValue: (latestData: any) => {
            return formatValue({ value: latestData.security_price_calcs?.['return_one_year'], formattingType: ValueTypes.Numeral });
        },
    }
]

export type FinancialSnapshotProps = {
    company: UseQueryResult<ScreenerDataWithGenericResultsKey<'company'>>;
    queryParams: CompanyParams;
    isLoading: boolean;
    customTheme?: Theme;
};

export function FinancialSnapshot({ company, queryParams, isLoading, customTheme }: FinancialSnapshotProps) {
    const [chartRef, setChartRef] = useState<React.RefObject<HighchartsReact.RefObject>>();

    const dailyPrices = company.data?.results?.company?.[0]?.['company_security.security_price.daily'] || [];
    const formattedDailyPrices = dailyPrices.map((priceData: any) => [new Date(priceData.as_of_date).getTime(), priceData.price_close_unadjusted]);

    const latestData = {
        security_price: company.data?.results?.company?.[0]?.['company_security.security_price']?.[0] || null,
        company_stats_latest: company.data?.results?.company?.[0]?.['company_security.company_stats_latest']?.[0] || null,
        security_price_calcs: company.data?.results?.company?.[0]?.['company_security.security_price_calcs']?.[0] || null,
        company_currency_symbol: (company.data?.results?.company?.[0]?.company_security?.company?.primary_currency_lid?.key || 'USD'),
        security_currency_symbol: (company.data?.results?.company?.[0]?.company_security?.security_trading?.currency_lid?.key || 'USD')
    };

    const noData = !latestData.security_price && !latestData.company_stats_latest && !latestData.security_price_calcs

    return (
        <>
            <ThemeProvider theme={createTheme()}>
                <Stack sx={containerSx}>
                    <Box sx={headerSx}>
                        <Grid container flexWrap='wrap' alignItems='center' gap='10px'>
                            <Grid item xs minWidth='200px'>
                                <TypographyStyle2 fontWeight={fontWeights.Medium} lineHeight='28px'>
                                    Financial Snapshot
                                </TypographyStyle2>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box p='10px 14px 28px 28px'>
                        <Box>
                            {(isLoading) && (
                                <Box sx={loadingCntrSx}>
                                    <Layout.Skeleton height='10px' />
                                </Box>
                            )}
                        </Box>
                        {
                            noData ? 
                            <Box sx={{ margin: '48px 0', width: '100%', textAlign: 'center' }}>
                                <TypographyStyle11>No data found</TypographyStyle11>
                            </Box> :
                            <>
                                <Box>
                                    {
                                        !isLoading && dailyPrices?.length > 0 &&
                                        <CFRAStockChart data={formattedDailyPrices} setChartRef={setChartRef} chartRef={chartRef} />
                                    }
                                </Box>
                                <Box>
                                    <Grid container rowGap={1.5} columnSpacing={{ xs: 0, md: 10 }} padding={'10px'}>
                                        {
                                            financialDataPoints.map((dp, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} md={6} display={'flex'} justifyContent={'space-between'} flexDirection={'row'}>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <TypographyStyle7 style={{ color: '#333333' }}>{dp.label}</TypographyStyle7>
                                                        </Box>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <TypographyStyle15 style={{ color: '#333333', fontWeight: 500 }}>{dp.getValue(latestData)}</TypographyStyle15>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                                <Box sx={{ textAlign: 'right', padding: '10px' }}>
                                    {
                                    latestData.security_price?.['as_of_date'] && 
                                        <TypographyStyle8>
                                            {`As of ${moment(latestData.security_price?.['as_of_date']).format('MMMM DD, YYYY')}`}
                                        </TypographyStyle8>
                                    }
                                </Box>
                            </>
                        }
                    </Box>
                </Stack>
            </ThemeProvider>
        </>
    );
}
