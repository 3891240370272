import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ComponentProps } from '../types/company';
import moment from 'moment';
import { CFRAScores, CFRAScoresProps } from './CFRAScores';
import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { ScoreHistory } from './CFRAScoreHistory';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { useContext } from 'react';
import { getScoreHistory, getQueryConfig, getScoresEntitlements } from '../api/company';
import { UseQueryResult } from 'react-query';
import { ScreenerDataWithGenericResultsKey } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { BarChart } from './ScoreBarChart';
import { cashFlowChartTheme } from 'components/themes/theme';
import { Box } from '@mui/material';

export function CashFlow({ company, handleLinkClick, reportID, enableQuery, hasLock, userEntitlements }: ComponentProps) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { userCashFlowEntitlements } = getScoresEntitlements(userEntitlements);

    const companyId = enableQuery
        ? company.data?.results.company[0].id
        : null;

    const scoreHistoryData = sendSingleRequest?.(
        getScoreHistory(
            enableQuery || false,
            {
                'company_security.company.cfra_company_id': { values: [companyId] },
            },
            'cash_flow_score_history',
        ),
        getQueryConfig('cashFlowScoreHistoryData', 'company'),
    ) as UseQueryResult<ScreenerDataWithGenericResultsKey<'company'>>;

    if (scoreHistoryData.isLoading) return <ETFCard.ETFCard isLoading={true} />;

    if (!enableQuery) return <ETFCard.ETFEmptyCard cardLabel='CFRA Cash Flow'></ETFCard.ETFEmptyCard>;

    const companyDetails = company.data?.results.company[0] as any;
    const cashFlowScores = companyDetails.frs_cash_flow_score_drivers;
    const trading = companyDetails.company_security?.security_trading;

    const stackContent = {
        title: 'Cash Flow',
        color: '#609EA0',
        value: cashFlowScores?.decile,
        date: moment(cashFlowScores?.date).format('MMMM YYYY'),
        textTitle: 'cash flow risk',
        ticker: `${trading.ticker_symbol} - ${trading.exchange_lid.exchange_code}`,
        handleClick: () => {
            if (handleLinkClick) {
                handleLinkClick(reportID || '');
            }
        },
        hasLock: hasLock,
    } as CFRAScoresProps;

    const entitled = userCashFlowEntitlements.length > 0;

    return (
        <ETFCard.ETFCard>
            <Grid container>
                <Grid item xs={12} sx={{ paddingBottom: '15px' }}>
                    <CFRAScores {...stackContent} entitled={entitled}/>
                </Grid>
                <Grid item xs={12}>
                    <ScoreHistory data={scoreHistoryData.data} entitled={entitled} dateField={'frs_cash_flow_score_history.date'} scoreLabel={'Cash Flow Score'} responsePropName={'frs_cash_flow_score_history'} />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: { xs: '15px', sm: '0' } }} position={!entitled ? 'relative' : 'static'}>
                    {!entitled && <Box sx={{ width: '70%',
                            height: '70%',
                            position: 'absolute',
                            left: '25%',
                            top: '15%',
                            zIndex: 100,
                            backdropFilter: 'blur(14px)',
                            display: 'flex' }}></Box>}
                    <BarChart
                        chartTitle='Cash Flow Score Drivers'
                        data={cashFlowScores}
                        metadataFields={company.data?._metadata.fields || []}
                        parentField='frs_cash_flow_score_drivers'
                        theme={cashFlowChartTheme}
                        entitled={entitled}
                    />
                </Grid>
            </Grid>
        </ETFCard.ETFCard>
    );
}
