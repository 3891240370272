import { ETFIconButton } from '@cfra-nextgen-frontend/shared';
import { ReactComponent as CloseIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/CloseIcon.svg';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    IdsAndSelectionsPreferencesActions,
    IdsAndSelectionsPreferencesEachElement,
    IdsAndSelectionsTypeExtension,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { Box, Grid } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';
import { getInformationPopupContent } from './InformationIcon';
import { useCallback } from 'react';

const preferencesConfiguration = {
    useUserPreferencesProps,
    selectorConfiguration: {
        selector: UserPreferencesSelectors[UserPreferences.AccountingLensRiskMonitor],
    },
};

export function InformationBanner({ onClose }: { onClose?: () => void }) {
    const [bannerStyles, setBannerStyles] = useState<CSSProperties | undefined>();

    const { getUserPreferences, setUserPreferences } = useUserPreferences(
        preferencesConfiguration?.useUserPreferencesProps,
    );

    useEffect(() => {
        const userPreferences = getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: PreferenceType.IdsAndSelectionsPreferences,
            selector: preferencesConfiguration.selectorConfiguration.selector,
        });

        if (userPreferences?.showBanner === false) {
            return;
        }

        setBannerStyles({
            height: '72px',
            opacity: '1',
            visibility: 'visible',
            marginBottom: '8px',
            padding: '10px 16px 8px 16px',
        });
    }, [getUserPreferences]);

    const handleTransitionEnd = useCallback((event: React.TransitionEvent) => {
        if (event.propertyName === 'height' && bannerStyles?.height === '0px') {
            onClose?.()
        }
    }, [bannerStyles, onClose]);

    return (
        <Box
            sx={{
                backgroundColor: '#1373E7',
                borderRadius: '10px 10px 0 0',
                height: '0px',
                opacity: '0',
                visibility: 'hidden',
                left: '0',
                marginBottom: '0px',
                marginLeft: '-16px',
                marginTop: '-8px',
                padding: '10px 16px 0px 16px',
                position: 'relative',
                top: '0',
                width: 'calc(100% + 32px)',
                transition: 'height 0.3s, visibility 0.3s, opacity 0.3s',
                ...bannerStyles,
            }}
            onTransitionEnd={handleTransitionEnd}>
            <Grid container alignItems='start' justifyContent='space-between'>
                <Grid item>
                    {getInformationPopupContent({
                        textSx: {
                            color: '#FFFFFF',
                            fontSize: '13px',
                        },
                        hazardListPrefix: 'NEW: ',
                    })}
                </Grid>
                <Grid item paddingTop='4px'>
                    <ETFIconButton
                        onClick={() => {
                            setBannerStyles({
                                height: '0px',
                                opacity: '0',
                                visibility: 'hidden',
                                marginBottom: '0px',
                                padding: '10px 16px 0px 16px',
                            });
                            setUserPreferences?.<
                                keyof typeof IdsAndSelectionsPreferencesActions,
                                IdsAndSelectionsTypeExtension
                            >(PreferenceType.IdsAndSelectionsPreferences, {
                                ...preferencesConfiguration.selectorConfiguration.selector,
                                action: IdsAndSelectionsPreferencesActions.SetShowBanner,
                                showBanner: false,
                            });
                            onClose?.();
                        }}
                        buttonFontSize={22}
                        Icon={CloseIcon}
                        enabledcolor='#98c1f2'
                        backgroundcolor='transparent'
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
