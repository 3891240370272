import { tooltipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, SxProps, ThemeProvider, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const Badge = styled(Box)(({ theme }) => ({
    background: '#F66C61',
    borderRadius: '3px',
    fontSize: '10px',
    fontFamily: fontFamilies.GraphikSemibold,
    color: '#fff',
    width: '26px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1px',
}));

export function BadgeBiggestConcerns({
    tooltipTitle = 'Company on the Biggest Concerns List',
    containerSx,
}: {
    tooltipTitle?: string;
    containerSx?: SxProps;
}) {
    return (
        <Box component='span' sx={containerSx}>
            <ThemeProvider theme={tooltipThemeV2}>
                <Tooltip title={tooltipTitle}>
                    <Badge component='span'>BC</Badge>
                </Tooltip>
            </ThemeProvider>
        </Box>
    );
}

export function BadgeHazardList({
    tooltipTitle = 'Company on Hazard List',
    containerSx,
}: {
    tooltipTitle?: string;
    containerSx?: SxProps;
}) {
    return (
        <Box component='span' sx={containerSx}>
            <ThemeProvider theme={tooltipThemeV2}>
                <Tooltip title={tooltipTitle}>
                    <Badge sx={{ backgroundColor: '#F6B361' }} component='span'>
                        HL
                    </Badge>
                </Tooltip>
            </ThemeProvider>
        </Box>
    );
}
