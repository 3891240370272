import { useAuthenticator } from '@aws-amplify/ui-react';
import { ReactComponent as SearchIcon2 } from '@cfra-nextgen-frontend/shared/src/assets/images/SearchIcon_2.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { PasswordResetForm } from '@cfra-nextgen-frontend/shared/src/components/PasswordRestForm/PasswordResetForm';
import { breakpointsThemeOptions as sharedBreakpointsThemeOptions } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { TypeSearch, TypeSearchProps } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { topNavigationHeight } from '@cfra-nextgen-frontend/shared/src/utils/lookAndFeel';
import {
    AppBar,
    Avatar,
    Box,
    Container,
    createTheme,
    Dialog,
    DialogContent,
    IconButton,
    styled,
    Tab,
    Tabs,
    ThemeOptions,
    ThemeProvider,
    Toolbar,
    Tooltip,
    useMediaQuery,
    useScrollTrigger,
} from '@mui/material';
import { deepmerge } from '@mui/utils';

import { SnackbarProvider } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Strategy, useVisibleItems } from '../../hooks/useVisibleItems';
import { Popup } from '../../types/popupContent';
import { UserEntitlementsResponseData } from '../../types/userEntitlements';
import { shadowBottomStylesVariant1 } from '../../utils/shadows';
import { ETFDownloadButton } from '../ETFButton/ETFDownloadButton';
import { ETFNavMenuIconButton } from '../ETFButton/ETFNavMenuIconButton';
import { AccordionsMenu, MenuItem } from '../ETFMenu/AccordionsMenu';
import { MenuVariant2 } from '../ETFMenu/MenuVariant2';
import { a11yProps } from '../layout/ETFTabPanel/ETFTabPanel';
import PopupContent from '../Popup/PopupContent';

export interface TopNavItem {
    name: string;
    href: string;
    type: 'link' | 'tab';
    popup?: Popup;
    shouldHideItem?: (entitlements?: UserEntitlementsResponseData) => boolean;
    icon?: string;
    length?: number;
}

const LogoImage = styled('img')(() => ({
    height: '52px',
    paddingTop: 'unset',
    paddingBottom: 'unset',
}));

const cfraLogoStyles = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
};

const initialThemeOptions = {
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#FFF',
                },
                root: {
                    borderBottom: 'solid #E4E5E9 1px',
                    borderTop: 'solid #3078B5 4px',
                    boxShadow: 'none',
                    zIndex: 1500,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#002B5A',
                    fontSize: 18,
                    fontFamily: fontFamilies.GraphikLight,
                    height: 48,
                    textTransform: 'uppercase',
                    width: 48,
                },
            },
        },
        MuiContainer: {
            defaultProps: {
                disableGutters: true,
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    marginLeft: '18px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#007AB9',
                    },
                    color: '#0B2958',
                    fontFamily: fontFamilies.GraphikMedium,
                    fontSize: '15px',
                    lineHeight: 1,
                    marginRight: '28px',
                    minWidth: '0px',
                    padding: 0,
                    paddingBottom: '25px',
                    paddingTop: '25px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#007AB9',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    background: '#007AB9',
                    borderRadius: '5px',
                    height: '4px',
                },
                root: {
                    paddingTop: '3px',
                },
            },
        },
        MuiToolbar: {
            defaultProps: {
                disableGutters: true,
            },
            styleOverrides: {
                root: {
                    display: 'flex',
                    height: topNavigationHeight,
                    justifyContent: 'space-between',
                },
            },
        },
    },
};

type TopNavigationProps = {
    topNavItems: Record<string, TopNavItem>;
    typeSearchProps: Omit<Omit<TypeSearchProps, 'showSearchInput'>, 'onClickOutsideCallback'>;
    maxWidth: string;
    logoSrc: string;
    breakpointsThemeOptions?: ThemeOptions;
};

export function SharedTopNavigation({
    topNavItems,
    typeSearchProps,
    maxWidth,
    logoSrc,
    breakpointsThemeOptions,
}: TopNavigationProps) {
    const [currentActiveTabHref, setCurrentActiveTabHref] = useState<string>();
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [popupContent, setPopupContent] = useState<Popup | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isHoveringPopup, setIsHoveringPopup] = useState(false);
    const [hoveredTab, setHoveredTab] = useState<number | null>(null);
    const [isHoveringTab, setIsHoveringTab] = useState(false);
    const [isWindowScrolled, setIsWindowScrolled] = useState(false);

    const isScreenDisplay = useMediaQuery('screen');

    const theme = useMemo(() => {
        const _breakpointsThemeOptions = breakpointsThemeOptions || sharedBreakpointsThemeOptions;
        const breakPointsTheme = createTheme(_breakpointsThemeOptions);

        return createTheme(
            deepmerge(
                _breakpointsThemeOptions,
                deepmerge(initialThemeOptions, {
                    components: {
                        MuiAppBar: {
                            defaultProps: {
                                position: isScreenDisplay ? 'sticky' : 'static',
                            },
                            styleOverrides: {
                                root: {
                                    ...(isWindowScrolled ? shadowBottomStylesVariant1 : {}),
                                },
                            },
                        },
                        MuiContainer: {
                            defaultProps: {
                                maxWidth: maxWidth,
                            },
                            styleOverrides: {
                                root: {
                                    maxWidth: maxWidth,
                                    [breakPointsTheme.breakpoints.down('lg')]: {
                                        padding: '0px 14px',
                                    },
                                    padding: '0px 36px',
                                },
                            },
                        },
                    },
                }),
            ),
        );
    }, [isScreenDisplay, isWindowScrolled, maxWidth, breakpointsThemeOptions]);

    const isDesktopVariant = useMediaQuery(theme.breakpoints.up('sm'));

    const { containerRef, visibleItems, moreItems } = useVisibleItems({
        options: Object.keys(topNavItems).map((key, index) => ({ key: index, value: topNavItems[key].href })),
        enableSort: false,
        strategy: Strategy.AllItemsDifferentSize,
        optionsLengths: Object.keys(topNavItems).map((key) => topNavItems[key].length || 0),
        enabled: isDesktopVariant,
        gap: 28,
        moreButtonWidth: 30,
    });

    const currentActiveTabIndex = useMemo(() => {
        const currentActiveTabIndexLocal = visibleItems.findIndex((item) => item.value === currentActiveTabHref);
        return currentActiveTabIndexLocal === -1 ? false : currentActiveTabIndexLocal;
    }, [currentActiveTabHref, visibleItems]);

    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);

    const signOutRef = useRef<() => void>(signOut);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { toResetPassword } = useAuthenticator();

    const handleContactUsClick = useCallback(() => {
        globalThis.analytics?.registerAction?.({
            action: 'footer : contact us',
        });
        navigate('/contact-us/');
    }, [navigate]);

    useEffect(() => {
        setCurrentActiveTabHref(`/${pathname.slice(1).split('/')[0]}`);
    }, [pathname]);

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            const selectedHref = visibleItems[newValue].value;
            setCurrentActiveTabHref(selectedHref);
            const selectedItem = Object.values(topNavItems).find((item) => item.href === selectedHref);

            if (!selectedItem) {
                return;
            }

            if (selectedItem.type === 'tab' && selectedItem.popup) {
                setPopupContent(selectedItem.popup);
                setAnchorEl(event.currentTarget as HTMLElement);
            } else {
                setPopupContent(undefined);
            }
        },
        [topNavItems, visibleItems],
    );

    useEffect(() => {
        const handleVisibility = () => {
            if (!isHoveringTab && !isHoveringPopup) {
                setPopupContent(undefined);
                setHoveredTab(null);
            }
        };

        const timeout = setTimeout(handleVisibility, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [isHoveringTab, isHoveringPopup]);

    const handleMouseEnterTab = useCallback(
        (event: React.MouseEvent<HTMLDivElement>, popup: Popup | undefined, index: number) => {
            setAnchorEl(event.currentTarget as HTMLElement);
            setPopupContent(popup);
            setIsHoveringTab(true);
            setHoveredTab(index);
        },
        [],
    );

    const handleMouseLeaveTab = useCallback(() => {
        setIsHoveringTab(false);
    }, []);

    const handlePopupMouseEnter = useCallback(() => {
        setIsHoveringPopup(true);
    }, []);

    const handlePopupMouseLeave = useCallback(() => {
        setIsHoveringPopup(false);
    }, []);

    const handleSearchClick = useCallback(() => {
        setShowSearchInput(true);
        setPopupContent(undefined);
        setHoveredTab(null);
    }, []);

    const isWindowScrolledTrigger = useScrollTrigger({
        disableHysteresis: true, // Immediate trigger
        threshold: 0, // Trigger as soon as the window scrolls
        target: window, // Ensure it listens to the global window scroll
    });

    useEffect(() => {
        setIsWindowScrolled((previousValue) => {
            if (previousValue !== isWindowScrolledTrigger) {
                return isWindowScrolledTrigger;
            }
            return previousValue;
        });
    }, [isWindowScrolledTrigger]);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleResetPassword = useCallback(() => {
        setIsPopupOpen(true);
        toResetPassword();
    }, [toResetPassword]);

    const handleClosePopup = useCallback(() => {
        setIsPopupOpen(false);
    }, []);

    const handleSearchBlur = () => {
        setShowSearchInput(false);
    };

    const typeSearch = useMemo(() => {
        return (
            <TypeSearch
                showSearchInput={showSearchInput}
                onClickOutsideCallback={handleSearchBlur}
                onEmptyInputClearCallback={handleSearchBlur}
                {...typeSearchProps}
            />
        );
    }, [showSearchInput, typeSearchProps]);

    const transformTopNavItemsToMenuItemsConfig = useCallback(
        (topNavItems: Array<TopNavItem>): Array<MenuItem> => {
            return Object.values(topNavItems).map((item) => {
                return {
                    name: item.name,
                    icon: item.icon,
                    onClick: () => navigate(item.href),
                    subItems: item.popup?.links?.map((subItem) => {
                        return {
                            name: subItem.name,
                            onClick: () => navigate(subItem.href),
                        };
                    }),
                };
            });
        },
        [navigate],
    );

    const threeDotsMenu = useMemo(() => {
        return (
            <AccordionsMenu
                variant='blueLinks'
                openComponent={<ETFDownloadButton tooltipText={''} />}
                menuItemsConfig={transformTopNavItemsToMenuItemsConfig(
                    moreItems.map((item) => Object.values(topNavItems).find((value) => value.href === item.value)!),
                )}
            />
        );
    }, [moreItems, topNavItems, transformTopNavItemsToMenuItemsConfig]);

    const burgerMenu = useMemo(() => {
        return (
            <AccordionsMenu
                variant='blackBorderLinks'
                openComponent={
                    <ETFNavMenuIconButton
                        enabledcolor='#0B2958'
                        hovercolor='#0B2958'
                        backgroundcolor='#FFFFFF'
                        sx={{
                            marginRight: '14px',
                            paddingRight: 0,
                            [theme.breakpoints.up('sm')]: {
                                display: 'none',
                            },
                        }}
                    />
                }
                menuItemsConfig={transformTopNavItemsToMenuItemsConfig(Object.values(topNavItems))}
            />
        );
    }, [theme.breakpoints, topNavItems, transformTopNavItemsToMenuItemsConfig]);

    const memorizedAppBar = useMemo(() => {
        return (
            <ThemeProvider theme={theme}>
                <AppBar>
                    <Container>
                        <Toolbar>
                            {!showSearchInput && (
                                <>
                                    {/* {burgerMenu} */}
                                    {!isDesktopVariant && burgerMenu}
                                    <Box
                                        sx={cfraLogoStyles}
                                        onClick={() => {
                                            navigate(topNavItems.Home.href);
                                        }}>
                                        <LogoImage
                                            src={logoSrc}
                                            alt='CFRA Institutional Logo'
                                            sx={{
                                                [theme.breakpoints.down('sm')]: {
                                                    height: '40px',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <div style={{ flexGrow: 1 }} />
                                    <Box
                                        ref={containerRef}
                                        sx={{
                                            flexBasis: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: '70px',
                                            justifyContent: 'center',
                                            marginLeft: '28px',
                                        }}>
                                        {isDesktopVariant && (
                                            <Tabs
                                                value={currentActiveTabIndex}
                                                onChange={handleChange}
                                                aria-label='top navigation tabs'>
                                                {visibleItems.map((item, index) => {
                                                    const topNavItem = topNavItems[Object.keys(topNavItems)[item.key]];
                                                    return (
                                                        <Tab
                                                            label={topNavItem.name}
                                                            key={index}
                                                            {...a11yProps(index)}
                                                            onClick={() => navigate(topNavItem.href)}
                                                            onMouseEnter={(event) =>
                                                                handleMouseEnterTab(event, topNavItem.popup!, index)
                                                            }
                                                            onMouseLeave={() => handleMouseLeaveTab()}
                                                            sx={{
                                                                color: hoveredTab === index ? '#3078B5' : '#0B2958',
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </Tabs>
                                        )}
                                        {isDesktopVariant && moreItems.length > 0 && (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>{threeDotsMenu}</Box>
                                        )}
                                    </Box>
                                    {popupContent && (
                                        <PopupContent
                                            popup={popupContent}
                                            anchorEl={anchorEl}
                                            open={true}
                                            onClose={() => {
                                                if (!isHoveringPopup) {
                                                    setPopupContent(undefined);
                                                }
                                            }}
                                            onMouseEnter={handlePopupMouseEnter}
                                            onMouseLeave={handlePopupMouseLeave}
                                        />
                                    )}

                                    <div style={{ flexGrow: 1 }} />
                                </>
                            )}

                            <Box
                                sx={
                                    showSearchInput
                                        ? {
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }
                                        : {
                                              display: 'block',
                                              width: {
                                                  xs: '45px',
                                                  lg: '220px',
                                              },
                                              ...(isDesktopVariant ? { marginLeft: '28px' } : {}),
                                          }
                                }>
                                {typeSearch}
                                {!showSearchInput && (
                                    <Box
                                        onClick={handleSearchClick}
                                        sx={{
                                            height: '44px',
                                            border: '1px solid #5792C3',
                                            borderRadius: '30px',
                                            backgroundColor: '#F3F5FC',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            paddingLeft: '20px',
                                            paddingRight: '18px',
                                            cursor: 'pointer',
                                            width: '220px',
                                            [theme.breakpoints.down('lg')]: {
                                                width: '45px',
                                                paddingLeft: '13px',
                                                fontSize: '0px',
                                                color: 'transparent',
                                            },
                                            fontSize: '14px',
                                            color: '#3C3C3C',
                                        }}>
                                        <span
                                            style={{
                                                fontFamily: fontFamilies.GraphikRegular,
                                                fontSize: 'inherit',
                                                color: 'inherit',
                                            }}>
                                            Search CFRA Content
                                        </span>
                                        <CustomSvgIcon
                                            component={SearchIcon2}
                                            viewBox='0 0 19 19'
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                fill: '#002B5A',
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {!showSearchInput && (
                                <MenuVariant2
                                    menuItems={[
                                        {
                                            itemName: user?.username || '',
                                        },
                                        {
                                            itemName: 'Contact Us',
                                            callback: handleContactUsClick,
                                        },
                                        {
                                            itemName: 'Reset Password',
                                            callback: handleResetPassword,
                                        },
                                        {
                                            itemName: 'Logout',
                                            callback: () => {
                                                signOutRef.current();

                                                // Clear analytics user data
                                                globalThis?.analytics?.fillUserDetails?.({});
                                            },
                                        },
                                    ]}>
                                    <Tooltip title={user?.username}>
                                        <IconButton
                                            sx={{
                                                marginLeft: !isDesktopVariant ? '5px' : '10px',
                                            }}>
                                            <Avatar>{user?.username?.charAt(0)}</Avatar>
                                        </IconButton>
                                    </Tooltip>
                                </MenuVariant2>
                            )}
                        </Toolbar>
                    </Container>
                </AppBar>
                <SnackbarProvider classes={{ containerRoot: 'cfra-snackbar-root' }}>
                    <Dialog open={isPopupOpen} sx={{ zIndex: '4000' }}>
                        <DialogContent>
                            <PasswordResetForm onCancel={handleClosePopup} />
                        </DialogContent>
                    </Dialog>
                </SnackbarProvider>
            </ThemeProvider>
        );
    }, [
        currentActiveTabIndex,
        handleChange,
        handleContactUsClick,
        handleMouseEnterTab,
        handleMouseLeaveTab,
        handlePopupMouseEnter,
        handlePopupMouseLeave,
        handleResetPassword,
        handleSearchClick,
        handleClosePopup,
        isHoveringPopup,
        isPopupOpen,
        navigate,
        popupContent,
        setPopupContent,
        showSearchInput,
        theme,
        topNavItems,
        typeSearch,
        anchorEl,
        hoveredTab,
        user?.username,
        burgerMenu,
        containerRef,
        isDesktopVariant,
        signOutRef,
        threeDotsMenu,
        visibleItems,
        moreItems.length,
        logoSrc,
    ]);

    return <ThemeProvider theme={theme}>{memorizedAppBar}</ThemeProvider>;
}
